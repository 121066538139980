<section [formGroup]="formGroup" class="{{control.dirty && control.invalid || question.options.dirty ? 'invalid': 'valid'}}">
    <mat-form-field [appearance]="Validation.getAppearance(question)" [hideRequiredMarker]="Validation.getHideRequired(question)" [floatLabel]="Validation.getFloatLabel(question)">
        <mat-label>{{question.label}}</mat-label>
        <input id="q-{{question.key}}-value" type="text" matInput [placeholder]="Validation.getPlaceholder(question)" [formControlName]="controlName" [matAutocomplete]="auto">

        <mat-autocomplete requireSelection id="q-{{question.key}}-value" #auto="matAutocomplete">
            <mat-option (onSelectionChange)="select($event)" *ngFor="let option of options | async" [value]="option.description">
                {{option.description}}
            </mat-option>
        </mat-autocomplete>
        <mat-hint *ngIf="!question.options.branding?.hint && control.value.length <= 2">Please start typing your address</mat-hint>
        <mat-hint *ngIf="question.options.branding?.hint">{{question.options.branding?.hint}}</mat-hint>

        
        <mat-spinner *ngIf="loading" color="accent" matSuffix diameter="25"></mat-spinner>

        <mat-error *ngFor="let error of Validation.getCustomValidationErrors(question, control)">
            {{error}}
        </mat-error>

        <mat-error *ngIf="control && control.hasError('required')">
            <span [innerHTML]="Validation.getRequiredMessage(question)">&nbsp;</span>
        </mat-error>

        <mat-error *ngIf="control && control.hasError('isSelected') && control.dirty" [innerHTML]="Validation.getCustomErrorMessage(question, 'addressMustSelect', 'Please ensure you have <strong>selected an address</strong>')">
            &nbsp;
        </mat-error>

    </mat-form-field>
</section>