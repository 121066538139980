<div class="pyro" style="display:none;" *ngIf="hasWon && !isAdmin && !demo">
    <div class="before"></div>
    <div class="after"></div>
</div>
<div class="image-preloading">
    <!-- This is just so loading the images in the slot machine happens BEFORE you run it -->
    <img *ngFor="let item of items" [src]="item" />
</div>
<div class="container">
    <h2 class="good-luck" *ngIf="game.options.goodLuck">{{game.options.goodLuck}}</h2>
    <div id="app">
        <div class="doors">
            <div class="door">
                <div class="boxes">
                    <div class="box"><img src="{{game.options.background}}"></div>
                </div>
            </div>

            <div class="door">
                <div class="boxes">
                    <div class="box"><img src="{{game.options.background}}"></div>
                </div>
            </div>

            <div class="door">
                <div class="boxes">
                    <div class="box"><img src="{{game.options.background}}"></div>
                </div>
            </div>
        </div>
        <div class="buttonContainer alignC">
            <button mat-raised-button color="accent" (click)="spin()"><span>Spin to reveal</span></button>
        </div>
    </div>
    <br />
    <h1 class="message" *ngIf="sentTrigger">
        {{hasWon ? game.options && game.options.win && game.options.win.message : game.options.lose.message}}
    </h1>
</div>