import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { Response } from 'src/interfaces/response';
import { SiteSettings } from 'src/interfaces/siteSettings';
import { WindowApp } from 'src/interfaces/windowApp';
import { PageBlockFormComponent } from '../components/page-block/page-block-form/page-block-form.component';
import { InteractService } from './interact.service';
import { Entrant } from 'src/interfaces/entrant';
import { UtilsPublicService } from './utils-public.service';

declare var window: WindowApp;

@Injectable({
    providedIn: 'root'
})
export class ExecuteService {
    modifiers: Function[] = [];

    constructor(private http: HttpClient, private UtilsPublic: UtilsPublicService, private router: Router, private Interact: InteractService, @Optional() @Inject('siteSettings') public siteSettings: SiteSettings) {

        if (typeof window !== 'undefined' && window && window.app)
            window.app.execute = this;
        if (typeof window !== 'undefined' && window && window.sdk)
            window.sdk.execute = this;
    }

    registerEntrantModifier(modifier: (entrant: Entrant) => void): number {
        this.modifiers.push(modifier);
        return this.modifiers.indexOf(modifier);
    }
    deregisterEntrantModifier(index: number): void {
        this.modifiers.splice(index, 1);
    }

    async executeEntrantModifiers(entrant: Entrant): Promise<Entrant> {
        for (const modifier of this.modifiers)
            modifier(entrant)

        return entrant;
    }
    // The execution of pipelines
    async run(payload: { pipelineId: string, data: any, reason: string, trigger?: string, database?: string, variablesMode: boolean, previewStep?: boolean, stepId?: string, siteId?: string }): Promise<Response> {
        try {
            const siteResponse: Response | any = await firstValueFrom(this.http.post('/api/execute', payload, { withCredentials: true }));

            return siteResponse;

        } catch (err: HttpErrorResponse | any) {
            if (err.error && err.error.message) {
                return { status: 'error', message: err.error.message }
            }
            return { status: 'error', message: err.error };
        }
    }

    async actions(context: PageBlockFormComponent | null, actions: any[], stopOnMessage: boolean = false): Promise<void> {
        for (const action of actions) {
            if (action.redirect) {
                this.UtilsPublic.redirect(action.redirect);
                return;
            }

            if (action.deepLinkId) {
                this.router.navigate([window.location.pathname], { queryParams: { id: action.deepLinkId } });
            }

            if (action.runGame && context) {
                setTimeout(() => {
                    context.setupResults();
                }, 500)
            }
            if (action && action.message && action.message.title && action.message.content) {
                this.Interact.displayDialog({ title: action.message.title, content: action.message.content });
                if (stopOnMessage) {
                    return;
                }
            }
        }
    }
}