import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { PrizeGame } from 'src/interfaces/prizeGame';
import { PrizeGamesTypes } from 'src/interfaces/prizeGamesTypes';

@Component({
    selector: 'app-prize-game-tap-reveal',
    templateUrl: './prize-game-tap-reveal.component.html',
    styleUrls: ['./prize-game-tap-reveal.component.scss']
})
export class PrizeGameTapRevealComponent implements OnInit {
    @Input() type!: keyof PrizeGamesTypes | string;
    @Input() hasWon!: boolean;
    @Input() game!: PrizeGame;
    @Input() revealedTrigger!: Function;
    @Input() isAdmin!: boolean;
    @Input() demo!: boolean;

    revealInterval: any;
    sentTrigger: boolean = false;


    constructor(@Inject(DOCUMENT) private document: Document) { }

    ngOnInit(): void {

        setTimeout(() => {
            this.setup();
            this.fixSizing();
        }, 250);

    }

    setup(): void {

        const prize: HTMLElement | null = this.document.querySelector('.tap-prize');


        if (prize)
            prize.onclick = () => { this.startReveal() };

        setTimeout(() => {
            this.fixSizing();
        }, 1000);
    }

    fixSizing(): void {
        const background: HTMLElement | null = this.document.querySelector('.tap-background');
        const shadow: HTMLElement | null = this.document.querySelector('.tap-shadow');
        if (shadow) {
            shadow.style.height = background?.clientHeight + 'px';
            shadow.style.width = background?.clientWidth + 'px';
        }
    }

    startReveal(): void {
        this.fixSizing();
        const prize: HTMLElement | null = this.document.querySelector('.tap-prize');
        if (prize) {

            const currentOpacity = parseFloat(prize?.style.opacity) || 0;
            if (currentOpacity === 1) {
                return;
            }

            if (currentOpacity >= 0.3) {
                if (this.document.querySelector('.pyro') && !this.demo)
                    (this.document.querySelector('.pyro') as HTMLElement).style.display = (this.hasWon ? 'block' : 'none');

                if (!this.sentTrigger && this.revealedTrigger) {
                    this.sentTrigger = true;
                    this.revealedTrigger();
                }
            }

            const newOpacity = ((currentOpacity + 0.1)).toFixed(1);
            prize.style.opacity = newOpacity;
        }
    }

    ngOnDestroy(): void {
        clearInterval(this.revealInterval);
    }
}
