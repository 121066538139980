<mat-card class="form-card" *ngIf="form && gameResults && game && !skipGame && form.game">

    <div *ngFor="let question of form.game.before" class="question question-{{question.type}} key-{{question.key}}">
        <app-page-block-form-question-content *ngIf="HiddenIf.isAcceptable(question, form, formGroup).result" [question]="question"></app-page-block-form-question-content>
    </div>

    <app-prize-game [type]="game.type" [hasWon]="gameHasWon" [game]="game" [revealedTrigger]="revealedTrigger()"></app-prize-game>

    <div *ngFor="let question of form.game.after" class="question question-{{question.type}} key-{{question.key}}">
        <app-page-block-form-question-content *ngIf="HiddenIf.isAcceptable(question, form, formGroup).result" [question]="question"></app-page-block-form-question-content>
    </div>



</mat-card>

<mat-card class="form-card {{gamePreview ? 'game-preview-container': ''}}" *ngIf="form && (!gameResults || skipGame) && !form.enabled.stepper">

    <app-prize-game (click)="revealForm()" *ngIf="gamePreview && game && !preview" [type]="game.type" [hasWon]="gameHasWon" [game]="game" [revealedTrigger]="revealedTrigger()" [preview]="true"></app-prize-game>

    <div class="form-container {{gamePreview && !preview ? 'hidden ' : ''}}">

        <form class="form game-preview-form" [formGroup]="formGroup" *ngIf="!loading">
            <div *ngFor="let question of form.questions" class="question question-{{question.type}} key-{{question.key}}">

                <app-page-block-form-question [block]="block" [question]="question" [entrant]="entrant" [formGroup]="formGroup"></app-page-block-form-question>


            </div>

            <button *ngIf="!preview" class="form-submit cta" [disabled]="!canSubmit()" color="accent" mat-raised-button (click)="submit()">{{form.branding.submitButtonText || 'Submit'}}</button>
        </form>
    </div>
</mat-card>

<mat-stepper *ngIf="form && (!gameResults || skipGame) && form && form.enabled && form.enabled.stepper && form.stepperQuestions && stepperFormGroups.length" class="form-stepper" [linear]="form.stepperQuestions[0].tabLinear"
    [labelPosition]="form.stepperQuestions[0].tabPosition || 'bottom'" #questionStepper>
    <mat-step [stepControl]="stepperFormGroups[idx]" color="accent" *ngFor="let stepper of form.stepperQuestions; let idx = index;" [label]="stepper.tabLabel">
        <form class="form" id="form-{{idx}}" [formGroup]="stepperFormGroups[idx]">
            <div *ngFor="let question of stepper.questions" class="question key-{{question.key}} question-{{question.type}}">
                <app-page-block-form-question *ngIf="HiddenIf.isAcceptable(question, form, stepperFormGroups).result" [attr.class]="'form-question page'" [block]="block" class="white-text" [formGroup]="stepperFormGroups[idx]"
                    [stepperFormGroups]="stepperFormGroups" [question]="question" [entrant]="entrant">
                </app-page-block-form-question>
            </div>

            <br />
            <br />
            <div *ngIf="!loading" class="buttons">
                <button *ngIf="shouldShowPreviousButton(idx)" mat-raised-button matStepperPrevious color="primary" class="previous">{{stepper.tabPrevious}}</button>
                <span class="spacer" *ngIf="(shouldShowPreviousButton(idx) && shouldShowSubmitButton(idx)) || (shouldShowPreviousButton(idx) && shouldShowNextButton(idx))"></span>
                <button *ngIf="questionStepper && shouldShowNextButton(idx)" mat-raised-button matStepperNext color="accent" class="next {{stepperFormGroups[idx] && stepperFormGroups[idx].valid ? 'valid':'invalid'}}">{{stepper.tabContinue}}</button>
                <button *ngIf="questionStepper && shouldShowSubmitButton(idx)" class="form-submit cta {{stepperFormGroups[idx] && stepperFormGroups[idx].valid ? 'valid':'invalid'}}" [disabled]="!canSubmit()" color="accent" mat-raised-button
                    (click)="submit()">{{form.branding.submitButtonText || 'Submit'}}</button>
            </div>
        </form>
    </mat-step>
</mat-stepper>