<section [formGroup]="formGroup">
    <p>{{question.label}}</p>
    <div class="container">
        <mat-form-field class="day" [appearance]="Validation.getAppearance(question)" [hideRequiredMarker]="Validation.getHideRequired(question)" [floatLabel]="Validation.getFloatLabel(question)">
            <mat-label>{{question.options.typeSpecificOptions.placeholderDay || 'Day'}}</mat-label>
            <mat-select [(ngModel)]="selectedDay" [ngModelOptions]="{standalone:true}" (selectionChange)="change()" (opened)="control.markAsTouched()">
                <mat-option *ngFor="let day of days" [value]="day">{{day}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="month" [appearance]="Validation.getAppearance(question)" [hideRequiredMarker]="Validation.getHideRequired(question)" [floatLabel]="Validation.getFloatLabel(question)">
            <mat-label>{{question.options.typeSpecificOptions.placeholderMonth || 'Month'}}</mat-label>
            <mat-select [(ngModel)]="selectedMonth" [ngModelOptions]="{standalone:true}" (selectionChange)="change()" (opened)="control.markAsTouched()">
                <mat-option *ngFor="let month of months" [value]="month">{{getMonth(month)}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="year" [appearance]="Validation.getAppearance(question)" [hideRequiredMarker]="Validation.getHideRequired(question)" [floatLabel]="Validation.getFloatLabel(question)">
            <mat-label>{{question.options.typeSpecificOptions.placeholderYear || 'Year'}}</mat-label>
            <mat-select [(ngModel)]="selectedYear" [ngModelOptions]="{standalone:true}" (selectionChange)="change()" (opened)="control.markAsTouched()">
                <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div *ngIf="control.touched">
        <mat-error *ngFor="let error of Validation.getCustomValidationErrors(question, control)">
            {{error}}
        </mat-error>

        <mat-error *ngIf="control && control.hasError('required')">
            <span [innerHTML]="Validation.getRequiredMessage(question)">&nbsp;</span>
        </mat-error>

        <mat-error *ngIf="control && control.hasError('invalidDate') && !control.hasError('required')" [innerHTML]="Validation.getCustomErrorMessage(question, 'dobInvalidMessage', 'Please ensure the date entered is correct')">
            &nbsp;
        </mat-error>
        <mat-error *ngIf="control && control.hasError('tooYoung') && !control.hasError('invalidDate') && !control.hasError('required')" [innerHTML]="Validation.getCustomErrorMessage(question, 'dobAgeMessage', 'Sorry, you are too young')">
            &nbsp;
        </mat-error>
    </div>

    <!--  -->
    <mat-form-field class="hide-input" *ngIf="!question.options.typeSpecificOptions.allowSubmit">
        <input id="q-{{question.key}}-value" type="text" matInput [placeholder]="Validation.getPlaceholder(question)" [formControlName]="controlName">
    </mat-form-field>
</section>