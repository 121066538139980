import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { UtilsPublicService } from 'src/app/services/utils-public.service';
import { ValidationService } from 'src/app/services/validation.service';
import { Entrant } from 'src/interfaces/entrant';
import { Question } from 'src/interfaces/question';

declare var window: Window;

@Component({
    selector: 'app-page-block-form-question-email',
    templateUrl: './page-block-form-question-email.component.html',
    styleUrls: ['./page-block-form-question-email.component.scss']
})
export class PageBlockFormQuestionEmailComponent implements OnInit {

    @Input() formGroup!: FormGroup;
    @Input() question!: Question;
    @Input() entrant?: Entrant;

    controlName: string = this.question?.key;
    control: FormControl = this.formBuilder?.control('', []);
    confirmControl: FormControl = this.formBuilder?.control('', []);
    requireConfirmation: boolean = true;
    answer: string = '';
    hasTypoOverride: boolean = false;
    validityInterval: any;
    ObjectKeys = Object.keys;

    constructor(private formBuilder: FormBuilder, private UtilsPublic: UtilsPublicService, public Validation: ValidationService) {
    }

    ngOnInit(): void {
        if (this.UtilsPublic.isBrowser()) {
            this.controlName = this.question.key;

            this.requireConfirmation = !!this.question.options?.typeSpecificOptions?.requireConfirmation;
            this.setValidation();
            this.Validation.setCustomValidation(this.question, this.question.options.validations, this.control);


            this.validityInterval = setInterval(() => {
                this.confirmControl.updateValueAndValidity();
                this.control.updateValueAndValidity();
            }, 1000);
        }
    }

    setValidation(): void {
        this.control = this.formBuilder.control('', [Validators.email, this.isGoodFormat()]);

        this.formGroup.addControl(this.controlName, this.control);

        if (this.requireConfirmation) {
            this.confirmControl = this.formBuilder.control('', [Validators.email, this.doesMatch()]);
            this.formGroup.addControl(this.controlName + '-confirm', this.confirmControl);
        }

        if (this.question.options.typeSpecificOptions && this.question.options.typeSpecificOptions.typoCheck && this.question.options.typeSpecificOptions.typoCheckForceStop) {
            if (this.requireConfirmation) {
                this.confirmControl.addValidators(this.validatorHasTypo());
            } else {
                this.control.addValidators(this.validatorHasTypo());
            }
        }

        this.control.valueChanges.subscribe(answer => this.answer = answer);

        setTimeout(() => {
            if (this.entrant && this.entrant?.answers) {
                this.control.setValue(this.entrant?.answers[this.question.key]);
                this.confirmControl.setValue(this.entrant?.answers[this.question.key]);
            }
        }, 250);
    }

    doesMatch(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const isValid = this.control.value === control.value;
            return isValid ? null : { 'matched': true };
        };
    }
    isGoodFormat(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const isValid = (/\@(.{2,})\.(.{2,})$/i).test(control.value);
            return isValid ? null : { 'email': true };
        };
    }

    emailHasTypo(email: string): boolean {
        if (email.length <= 3)
            return false;

        const typosDoesContain = [
            /(\S)\1{1,}(@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/, // Transpositions and Repeated Characters
            /(^[A-Z0-9._%+-]+)(@[a-z0-9.-]+\.[a-zA-Z]{2,}$)/, // Case Sensitivity Errors
            /(hotmaik|hotmaip|hotnail|gmal|gnail|gmaik|hotmqil|gamil|gmil|gmmail|gmai|yaho|yhoo|gemel|gmali|gamail|icloid)\./,
        ];
        const typosDoesNotContain = [
            /\.(com|org|net|gov|edu|co\.uk|de|cn|fr|nl|ru|jp|br|it|au|ca|es|us|se|mx)$/
        ]
        for (const pattern of typosDoesContain) {
            if (pattern.test(email)) {
                return true;
            }
        }
        for (const pattern of typosDoesNotContain) {
            if (!pattern.test(email)) {
                return true;
            }
        }
        return false; // Return false if no typos are found
    }

    validatorHasTypo(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const hasTypo = this.hasTypoOverride ? false : this.emailHasTypo(control.value);

            return hasTypo ? { 'typo': true } : null;
        };
    }

    ngOnDestroy(): void {
        clearInterval(this.validityInterval);
        this.formGroup.removeControl(this.controlName);
        this.formGroup.removeControl(this.controlName + '-confirm');
    }
}
