import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { UtilsPublicService } from 'src/app/services/utils-public.service';
import { PrizeGame } from 'src/interfaces/prizeGame';
import { PrizeGameCustom } from 'src/interfaces/prizeGameCustom';
import { PrizeGamesTypes } from 'src/interfaces/prizeGamesTypes';
import { WindowApp } from 'src/interfaces/windowApp';

declare var window: WindowApp;

@Component({
    selector: 'app-prize-game',
    templateUrl: './prize-game.component.html',
    styleUrls: ['./prize-game.component.scss']
})
export class PrizeGameComponent implements OnInit {
    @Input() type!: keyof PrizeGamesTypes | string;
    @Input() hasWon!: boolean;
    @Input() game!: PrizeGame | PrizeGameCustom;
    @Input() revealedTrigger!: Function;
    @Input() demo: boolean = false;
    @Input() preview: boolean = false;
    @Input() gameId?: string;
    @Input() siteId?: string;

    loading: boolean = true;
    isAdmin: boolean = false;
    revealedTriggerEvent!: Function;

    constructor(private UtilsPublic: UtilsPublicService, private http: HttpClient) {
    }


    async ngOnInit(): Promise<void> {
        if (this.game && !this.gameId && '_id' in this.game && this.game._id) {
            this.gameId = this.game._id.toString();
        }
        if (this.UtilsPublic.isBrowser() && window.location.pathname.indexOf('/admin') === 0) {
            this.isAdmin = true; // no pyro on admin
        }

        if (this.gameId) {
            // avoid using the GamesService as it exposes a lot
            const siteResponse: Response | any = await firstValueFrom(this.http.get(this.UtilsPublic.serverUrl('/api/games?_id=' + this.gameId), { withCredentials: true }));
            if (siteResponse && siteResponse.status === 'ok' && siteResponse.data) {
                this.game = siteResponse.data as PrizeGameCustom;
                this.type = 'custom';

                this.loading = false;
            } else {
                this.loading = false;

            }

        } else {
            this.loading = false;
        }


        this.revealedTriggerEvent = () => {
            this.revealedTrigger();
            setTimeout(() => {
                window.sdk.events.trigger('prizeReveal');
            }, 250);
        }
    }
}
