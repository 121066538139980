import { Injectable, } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Question } from 'src/interfaces/question';
import { QuestionTypes } from 'src/interfaces/questionTypes';
import { QuestionValidation } from 'src/interfaces/questionValidation';

@Injectable({
    providedIn: 'root'
})
export class ValidationService {
    available: { [key: string]: string[] } = {
        min: ['number'],
        max: ['number'],
        minLength: ['text', 'email', 'code', 'telephone', 'textarea', 'mask'],
        maxLength: ['text', 'email', 'code', 'telephone', 'textarea', 'mask'],
        pattern: ['text', 'email', 'code', 'telephone', 'textarea', 'mask'],
        custom: ['text', 'email', 'code', 'telephone', 'textarea', 'mask']
    };
    questionTypesWithDefaultFeatures: (keyof typeof QuestionTypes)[] = ['email', 'telephone'];

    constructor(private sanitizer?: DomSanitizer) { }

    setCustomValidation(question: Question, validations?: { [key: string]: QuestionValidation | any }, control?: FormControl) {
        try {
            if (typeof validations !== 'object')
                validations = {};

            if (question && validations && control) {
                for (const type in this.available) {
                    if (this.available[type].indexOf(question.type) !== -1 && validations && type in validations && validations[type] && validations[type].message) {
                        if (type in Validators) {

                            const validators: any = Validators;
                            const validator: Function = validators[type] as Function;
                            const validatorValue: any = validations[type][type] as any;

                            if (typeof validator === 'function' && validatorValue) {
                                if (type === 'pattern') {
                                    control.addValidators(validator(new RegExp(validatorValue, validations[type]['patternFlags'] || '')));
                                } else {
                                    control.addValidators(validator(validatorValue));
                                }
                            }
                        } else if (type === 'custom' && validations[type].custom) {
                            const custom: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
                                return control.value.toString().match(new RegExp(validations![type].custom)) ? null : { 'custom': true };
                            }

                            control.addValidators(custom);
                        }
                    }
                }

                if (question.options.dirty) {
                    control.markAsDirty();
                    control.markAsTouched();
                }

                if (question.options.required) {
                    control.addValidators(Validators.required);
                }

                if (question.options.browserStoreValue && control && localStorage)
                    control.valueChanges.subscribe(value => localStorage.setItem('q-' + question.key, value));
            }

        } catch (err) {
            // console.error(err);
        }
    }


    getCustomValidationAttribute(question: Question, type: string): number | string | null {
        if (type && question && question.options.validations && type in question.options.validations && question.options.validations[type] && question.options.validations[type].force) {
            if (question.options.validations[type].force && (type === 'minLength' || type === 'maxLength') && question.options.validations[type][type]) {
                return question.options.validations[type][type] || null;
            }
        }

        return null;
    }

    getCustomValidationErrors(question: Question, control: FormControl): string[] {
        let messages: string[] = [];
        if (control.dirty && control.invalid && question.options.validations) {
            for (const type in question.options.validations) {
                if (type && control.hasError(type.toLowerCase())) {
                    if (question.options.validations[type].message) {
                        messages.push(question.options.validations[type].message);
                    } else {
                        messages.push('This is invalid.');
                    }
                }
            }
            return messages;
        } else {
            return [];
        }
    }

    getRequiredMessage(question: Question): SafeHtml {
        const html = question.options.requiredMessage ? question.options.requiredMessage : 'This is <strong>required</strong>';
        return this.sanitizer?.bypassSecurityTrustHtml(html) || '';
    }

    getCustomErrorMessage(question: Question, messageKey: string, failSafe: string): SafeHtml {
        if (question && question.options.typeSpecificOptions && messageKey in question.options.typeSpecificOptions) {
            return question.options.typeSpecificOptions[messageKey] || failSafe || '';
        }
        return failSafe || '';
    }

    getAppearance(question: Question): MatFormFieldAppearance {
        return question.options && question.options.branding && question.options.branding.appearance ? question.options.branding.appearance : 'outline';
    }
    getHideRequired(question: Question): boolean {
        return question.options && question.options.branding && question.options.branding.hideRequired ? question.options.branding.hideRequired : false;
    }
    getFloatLabel(question: Question): 'auto' | 'always' {
        return question.options && question.options.branding && question.options.branding.floatLabel ? question.options.branding.floatLabel : 'auto';
    }
    getPlaceholder(question: Question): string {
        return question.options && question.options.branding && question.options.branding.placeholder ? question.options.branding.placeholder : '';
    }

}