import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { Entrant } from 'src/interfaces/entrant';
import { Question } from 'src/interfaces/question';

@Component({
    selector: 'app-page-block-form-question-telephone',
    templateUrl: './page-block-form-question-telephone.component.html',
    styleUrls: ['./page-block-form-question-telephone.component.scss']
})
export class PageBlockFormQuestionTelephoneComponent implements OnInit {


    @Input() formGroup!: FormGroup;
    @Input() question!: Question;
    @Input() entrant?: Entrant;

    controlName: string = this.question?.key;
    control: FormControl = this.formBuilder?.control('', []);

    constructor(private formBuilder: FormBuilder, public Validation: ValidationService) {
    }

    ngOnInit(): void {
        this.controlName = this.question.key;

        this.setValidation();
        this.Validation.setCustomValidation(this.question, this.question.options.validations, this.control);
    }

    setValidation(): void {
        const telephonePattern: RegExp = new RegExp(/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/);
        const validatorFunctions = [Validators.pattern(telephonePattern)];

        this.control.setValidators(validatorFunctions);
        this.formGroup.addControl(this.controlName, this.control);

        setTimeout(() => {
            if (this.entrant && this.entrant?.answers) {
                this.control.setValue(this.entrant?.answers[this.question.key]);
            }
        }, 250);
    }

    ngOnDestroy(): void {
        this.formGroup.removeControl(this.controlName);
    }
}