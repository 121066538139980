export const environment = {
    mode: 'production',
    blobLocation: 'productionpromonow',
    production: true,
    mapsKey: 'AIzaSyBAmL6xvYjs6KAzlY9SMXkJqXFt5x4RXkk',
    host: 'engine.promo-now.com',
    wsProtocol: 'wss',
    wsPort: '443',
    recaptchaV3: '6LdretQpAAAAABWCwV6i3x7GWMHAJSp7kuJWaS5m',
    protocol: 'https',
};
