import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingComponent } from './components/loading/loading.component';
import { CommonModule } from '@angular/common';
import { BarcodeComponent } from './components/barcode/barcode.component';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageBlockComponent } from './components/page-block/page-block.component';
import { PageBlockContentComponent } from './components/page-block/page-block-content/page-block-content.component';
import { ButtonHappyComponent } from './components/button-happy/button-happy.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RecaptchaModule } from 'ng-recaptcha';
import { MatStepperModule } from '@angular/material/stepper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatSliderModule } from '@angular/material/slider';
//

import { PageBlockFormComponent } from './components/page-block/page-block-form/page-block-form.component';
import { PageBlockFormQuestionComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question.component';
import { PageBlockFormQuestionTextComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question-text/page-block-form-question-text.component';
import { PageBlockFormQuestionEmailComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question-email/page-block-form-question-email.component';
import { PageBlockFormQuestionTelephoneComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question-telephone/page-block-form-question-telephone.component';
import { PageBlockFormQuestionTextareaComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question-textarea/page-block-form-question-textarea.component';
import { PageBlockFormQuestionRadioComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question-radio/page-block-form-question-radio.component';
import { PageBlockFormQuestionCheckboxComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question-checkbox/page-block-form-question-checkbox.component';
import { PageBlockFormQuestionSelectComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question-select/page-block-form-question-select.component';
import { PageBlockFormQuestionContentComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question-content/page-block-form-question-content.component';
import { PageBlockFormQuestionRecaptchaComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question-recaptcha/page-block-form-question-recaptcha.component';
import { PageBlockFormQuestionCodeComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question-code/page-block-form-question-code.component';
import { PageBlockFormQuestionButtonComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question-button/page-block-form-question-button.component';
import { PageBlockFormQuestionAutocompleteComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question-autocomplete/page-block-form-question-autocomplete.component';
import { PageBlockFormQuestionFileComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question-file/page-block-form-question-file.component';
import { PageBlockFormQuestionAddressComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question-address/page-block-form-question-address.component';
import { PageBlockFormQuestionDateComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question-date/page-block-form-question-date.component';
import { PageBlockFormQuestionDOBComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question-dob/page-block-form-question-dob.component';
import { PageBlockFormQuestionBotComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question-bot/page-block-form-question-bot.component';
import { PageBlockFormQuestionMaskComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question-mask/page-block-form-question-mask.component';
import { PageBlockFormQuestionSliderComponent } from './components/page-block/page-block-form/page-block-form-question/page-block-form-question-slider/page-block-form-question-slider.component';

//
import { PrizeGameComponent } from './components/prize-game/prize-game.component';
import { PrizeGameSlideRevealComponent } from './components/prize-game/prize-game-slide-reveal/prize-game-slide-reveal.component';
import { PrizeGameTapRevealComponent } from './components/prize-game/prize-game-tap-reveal/prize-game-tap-reveal.component';
import { PrizeGameSlotMachineComponent } from './components/prize-game/prize-game-slot-machine/prize-game-slot-machine.component';
import { PrizeGameCardsHighLowComponent } from './components/prize-game/prize-game-cards-high-low/prize-game-cards-high-low.component';
import { PrizeGameScratchRevealComponent } from './components/prize-game/prize-game-scratch-reveal/prize-game-scratch-reveal.component';
import { PrizeGameCustomComponent } from './components/prize-game-custom/prize-game-custom.component';
//
import { WidgetComponent } from './components/widget/widget.component';
import { WidgetNavigationComponent } from './components/widget/widget-navigation/widget-navigation.component';
import { WidgetFooterComponent } from './components/widget/widget-footer/widget-footer.component';
import { WidgetHeroImageComponent } from './components/widget/widget-hero-image/widget-hero-image.component';
import { WidgetHtmlComponent } from './components/widget/widget-html/widget-html.component';
import { WidgetCookiesComponent } from './components/widget/widget-cookies/widget-cookies.component';
import { WidgetTermsComponent } from './components/widget/widget-terms/widget-terms.component';
import { WidgetShareComponent } from './components/widget/widget-share/widget-share.component';
import { WidgetFaqsComponent } from './components/widget/widget-faqs/widget-faqs.component';
import { WidgetLoadingComponent } from './components/widget/widget-loading/widget-loading.component';
// 
import { HttpClientModule } from '@angular/common/http';

//
//

@NgModule({
    declarations: [
        LoadingComponent,
        BarcodeComponent,
        PageBlockComponent,
        PageBlockContentComponent,
        ButtonHappyComponent,
        //
        PageBlockFormComponent,
        PageBlockFormQuestionComponent,
        PageBlockFormQuestionContentComponent,
        PageBlockFormQuestionTextComponent,
        PageBlockFormQuestionEmailComponent,
        PageBlockFormQuestionTelephoneComponent,
        PageBlockFormQuestionTextareaComponent,
        PageBlockFormQuestionRadioComponent,
        PageBlockFormQuestionCheckboxComponent,
        PageBlockFormQuestionSelectComponent,
        PageBlockFormQuestionRecaptchaComponent,
        PageBlockFormQuestionCodeComponent,
        PageBlockFormQuestionButtonComponent,
        PageBlockFormQuestionAutocompleteComponent,
        PageBlockFormQuestionFileComponent,
        PageBlockFormQuestionAddressComponent,
        PageBlockFormQuestionDateComponent,
        PageBlockFormQuestionDOBComponent,
        PageBlockFormQuestionBotComponent,
        PageBlockFormQuestionMaskComponent,
        PageBlockFormQuestionSliderComponent,
        //
        PrizeGameComponent,
        PrizeGameSlideRevealComponent,
        PrizeGameTapRevealComponent,
        PrizeGameSlotMachineComponent,
        PrizeGameScratchRevealComponent,
        PrizeGameCardsHighLowComponent,
        PrizeGameCustomComponent,
        //
        WidgetComponent,
        WidgetNavigationComponent,
        WidgetFooterComponent,
        WidgetHeroImageComponent,
        WidgetHtmlComponent,
        WidgetShareComponent,
        WidgetCookiesComponent,
        WidgetTermsComponent,
        WidgetLoadingComponent,
        WidgetFaqsComponent,
    ],
    imports: [
        DragDropModule,
        CommonModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSelectModule,
        MatFormFieldModule,
        MatSlideToggleModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        RecaptchaModule,
        MatStepperModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        HttpClientModule,
        MatExpansionModule,
        MatSliderModule,
    ],
    providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }],
    exports: [
        LoadingComponent,
        BarcodeComponent,
        PageBlockComponent,
        PageBlockContentComponent,
        ButtonHappyComponent,
        PageBlockFormComponent,
        PageBlockFormQuestionComponent,
        PageBlockFormQuestionContentComponent,
        PageBlockFormQuestionTextComponent,
        PageBlockFormQuestionEmailComponent,
        PageBlockFormQuestionTelephoneComponent,
        PageBlockFormQuestionTextareaComponent,
        PageBlockFormQuestionRadioComponent,
        PageBlockFormQuestionCheckboxComponent,
        PageBlockFormQuestionSelectComponent,
        PageBlockFormQuestionRecaptchaComponent,
        PageBlockFormQuestionCodeComponent,
        PageBlockFormQuestionButtonComponent,
        PageBlockFormQuestionAutocompleteComponent,
        PageBlockFormQuestionFileComponent,
        PageBlockFormQuestionAddressComponent,
        PageBlockFormQuestionDateComponent,
        PageBlockFormQuestionDOBComponent,
        PageBlockFormQuestionBotComponent,
        PageBlockFormQuestionMaskComponent,
        PageBlockFormQuestionSliderComponent,
        //
        PrizeGameComponent,
        PrizeGameSlideRevealComponent,
        PrizeGameTapRevealComponent,
        PrizeGameSlotMachineComponent,
        PrizeGameCardsHighLowComponent,
        PrizeGameCustomComponent,
        //
        WidgetComponent,
        WidgetNavigationComponent,
        WidgetFooterComponent,
        WidgetHeroImageComponent,
        WidgetHtmlComponent,
        WidgetFaqsComponent,
    ],
})
export class SharedModule { }
