import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { GenerateService } from 'src/app/services/generate.service';
import { InteractService } from 'src/app/services/interact.service';
import { UtilsPublicService } from 'src/app/services/utils-public.service';
import { PrizeGame } from 'src/interfaces/prizeGame';
import { PrizeGamesTypes } from 'src/interfaces/prizeGamesTypes';
import Generate from 'src/server/utils/generate';

interface Card {
    suit: string;
    card: number;
}
@Component({
    selector: 'app-prize-game-cards-high-low',
    templateUrl: './prize-game-cards-high-low.component.html',
    styleUrls: ['./prize-game-cards-high-low.component.scss'],
})
export class PrizeGameCardsHighLowComponent implements OnInit, OnDestroy {
    @Input() type!: keyof PrizeGamesTypes | string;
    @Input() hasWon!: boolean;
    @Input() game!: PrizeGame;
    @Input() revealedTrigger!: Function;
    @Input() isAdmin!: boolean;
    @Input() demo!: boolean;
    @Input() preview: boolean = false;

    revealInterval: any;
    sentTrigger: boolean = false;
    suits: string[] = ['clubs', 'diamonds', 'hearts', 'spades'];
    hasDealt: boolean = false;
    lastAction: string = '';
    dealtCards: Card[] = [];
    showControls: boolean = true;
    backOfCards: number[] = [0, 1, 2, 3, 4];
    deckURL: string = '';

    Generate: Generate;
    constructor(@Inject(DOCUMENT) private document: Document, private UtilsPublic: UtilsPublicService, private Interact: InteractService) {
        this.Generate = new Generate();
    }

    ngOnInit(): void {
        if (this.UtilsPublic.isBrowser()) {
            this.startup();

        }
    }

    startup(): void {
        this.deckURL = this.game.options.deckURL || '';
        this.showControls = true;
        this.dealtCards = [];
        this.hasDealt = false;

    }
    deal(): void {
        if (this.preview)
            return;
            
        if (this.hasWon) {
            const startingCard: Card = this.getRandomCard(6, 6);
            this.dealtCards.push(startingCard);
        } else {
            const startingCard: Card = this.getRandomCard(3, 12);
            this.dealtCards.push(startingCard);
        }
        this.hasDealt = true;
    }

    getRandomCard(numStart: number, numEnd: number): Card {
        const currentCard = this.getCurrentCard();

        const cardNumber = this.Generate.generateRandomNumber(numStart, numEnd);
        const cardSuit = this.suits[this.Generate.generateRandomNumber(0, 3)];

        if (this.dealtCards.find((card) => card.card === cardNumber && card.suit === cardSuit)) {
            return this.getRandomCard(numStart, numEnd);
        }
        if (currentCard && cardNumber === currentCard.card) {
            if (numStart === currentCard.card) { // if going higher
                return this.getRandomCard(numStart + 1, numEnd);
            } else if (numEnd === currentCard.card) { // if going lower
                return this.getRandomCard(numStart, numEnd - 1);
            }
        }



        return {
            suit: cardSuit,
            card: cardNumber
        }


    }
    getCurrentCard(): Card {
        return this.dealtCards[this.dealtCards.length - 1];
    }

    shouldContinue(currentCard: Card, direction: string): boolean {
        if (this.hasWon) {
            return true;
        }

        if (currentCard.card >= 12 && direction === 'high') { // if they've got a queen and picked high, fail here...
            return false;
        } else if (currentCard.card <= 2 && direction === 'low') { // if they've got a two and picked low, fail here
            return false;
        }

        if (this.dealtCards.length === 1) { // never fail on the second card (unless above ^)
            return true;
        }
        if (this.dealtCards.length < 2) { // at 3 cards in, one in three chance of losing here
            return this.Generate.generateRandomNumber(1, 3) !== 3;
        }
        if (this.dealtCards.length < 3) { // at 4 cards in, one in two chance of losing here
            return this.Generate.generateRandomNumber(1, 2) !== 2;
        }
        if (this.dealtCards.length === 4) { // if at the last card, always lose here
            return false;
        }

        return false;
    }

    endGame(): void {
        this.showControls = false;

        if (!this.sentTrigger && this.revealedTrigger) {
            this.sentTrigger = true;
            this.revealedTrigger();

            if (this.document.querySelector('.pyro') && !this.demo)
                (this.document.querySelector('.pyro') as HTMLElement).style.display = (this.hasWon ? 'block' : 'none');
        }
    }

    pickHigh(): void {
        this.document.querySelector('.featured-card img')?.classList.remove('real-card');

        const current: Card = this.getCurrentCard();
        if (this.hasWon) {
            const stepsLeft = 5 - (this.dealtCards.length);
            const maxCard = 13 - stepsLeft;

            this.dealtCards.push(this.getRandomCard(current.card, maxCard));
        } else {
            if (this.shouldContinue(current, 'high')) {
                this.dealtCards.push(this.getRandomCard(current.card, 12));
            } else {
                this.dealtCards.push(this.getRandomCard(1, current.card));
                this.endGame();
            }
        }

        if (this.dealtCards.length === 5) {
            this.endGame();
        }

        setTimeout(() => { this.document.querySelector('.featured-card img')?.classList.add('real-card') }, 0);
    }

    pickLow(): void {
        this.document.querySelector('.featured-card img')?.classList.remove('real-card');

        const current: Card = this.getCurrentCard();
        if (this.hasWon) {
            const stepsLeft = 5 - (this.dealtCards.length);
            const minCard = 1 + stepsLeft;

            this.dealtCards.push(this.getRandomCard(minCard, current.card));
        } else {
            if (this.shouldContinue(current, 'low')) {
                this.dealtCards.push(this.getRandomCard(2, current.card));
            } else {
                this.dealtCards.push(this.getRandomCard(current.card, 13));
                this.endGame();
            }
        }

        if (this.dealtCards.length === 5) {
            this.endGame();
        }


        setTimeout(() => { this.document.querySelector('.featured-card img')?.classList.add('real-card') }, 0);

    }

    ngOnDestroy(): void {

    }
}
