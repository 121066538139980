import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { Entrant } from 'src/interfaces/entrant';
import { Question } from 'src/interfaces/question';
import { PageBlockFormQuestionComponent } from '../page-block-form-question.component';
import { ExecuteService } from 'src/app/services/execute.service';
import moment from 'moment';

declare var window: Window;
interface DateQuestionOptions {
    pickerOnly: boolean,
    pastOnly: boolean,
    maxDate: string,
    minDate: string,
    minDateMessage?: string,
    maxDateMessage?: string,
}
@Component({
    selector: 'app-page-block-form-question-date',
    templateUrl: './page-block-form-question-date.component.html',
    styleUrls: ['./page-block-form-question-date.component.scss']
})
export class PageBlockFormQuestionDateComponent implements OnInit {

    @Input() formGroup!: FormGroup;
    @Input() question!: Question;
    @Input() entrant?: Entrant;
    @Input() controller?: PageBlockFormQuestionComponent;

    controlName: string = this.question?.key;
    control: FormControl = this.formBuilder?.control('', []);
    minDate: Date = new Date('1900-01-01');
    maxDate: Date = new Date('3000-01-01');

    constructor(private formBuilder: FormBuilder, public Validation: ValidationService, private Execute: ExecuteService) {
    }

    ngOnInit(): void {
        this.controlName = this.question.key;

        this.setValidation();
        this.Validation.setCustomValidation(this.question, this.question.options.validations, this.control);
    }

    setValidation(): void {
        this.formGroup.addControl(this.controlName, this.control);

        setTimeout(() => {
            if (this.entrant && this.entrant?.answers) {
                const date = this.entrant?.answers[this.question.key].split('-').reverse().join('-'); // flip it from DD-MM-YYYY to YYYY-MM-DD
                this.control.setValue(moment(date).toDate());
            }
        }, 250);

        this.Execute.registerEntrantModifier(entrant => entrant.answers[this.question.key] = moment(entrant.answers[this.question.key]).utc(true).format('DD-MM-YYYY'));

        if (this.question.options.typeSpecificOptions) {
            const options: DateQuestionOptions = this.question.options.typeSpecificOptions;
            if (options.pickerOnly)
                setTimeout(() => {
                    (document.querySelector(`.datepicker-${this.controlName}`)?.setAttribute('disabled', 'true'));

                    const input = document.querySelector(`.datepicker-${this.controlName}`);
                    if (input) {
                        const overlay: HTMLElement = document.querySelector(`.datepicker-${this.controlName}-input-overlay`) as HTMLElement;
                        if (overlay) {
                            overlay.style.height = input.clientHeight + 'px';
                            overlay.style.top = '-' + input.clientHeight + 'px';
                            overlay.style.marginBottom = '-' + input.clientHeight + 'px';
                        }
                    }
                }, 500);

            if (options.minDate)
                this.minDate = moment(options.minDate).utc(true).toDate();
            if (options.maxDate)
                this.maxDate = moment(options.maxDate).utc(true).toDate();
            if (options.pastOnly)
                this.maxDate = (this.maxDate.valueOf() > new Date().valueOf() ? new Date() : moment(options.maxDate).utc(true).toDate());

        }
    }
    isMobile(): boolean {
        return window.screen.width <= 500;
    }

    ngOnDestroy(): void {
        this.formGroup.removeControl(this.controlName);
    }

}
