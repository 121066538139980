export default class Generate {
    generateRandomNumber(min: number, max: number): number {
        if (min === max) {
            return min;
        }
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    generateOTP(length: number, charSet: string): string {
        charSet = charSet || '0123456789';
        let otp = '';
        for (let i = 0; i < length; i++)
            otp += (charSet.charAt(Math.floor(Math.random() * charSet.length)))

        return otp;
    }
}