<mat-card class="scratch-container">
    <div class="pyro" style="display:none;" *ngIf="hasWon && !isAdmin && !demo">
        <div class="before"></div>
        <div class="after"></div>
    </div>

    <!-- Prize image that will be revealed -->
    <div class="scratch-prize">
        <img [src]="hasWon ? game.options.win.image : game.options.lose.image" class="prize-image">
    </div>

    <!-- Canvas element for the scratch effect -->
    <canvas #scratchCanvas></canvas>
</mat-card>
<p *ngIf="sentTrigger" class="scratch-message">{{ hasWon ? game.options.win.message : game.options.lose.message }}</p>
<p *ngIf="!sentTrigger && game.options.goodLuck" class="scratch-message">{{game.options.goodLuck}}</p>