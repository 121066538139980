import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { BrandingService } from 'src/app/services/branding.service';
import { ExecuteService } from 'src/app/services/execute.service';
import { hiddenIfService } from 'src/app/services/hiddenIf.service';
import { InteractService } from 'src/app/services/interact.service';
import { StepsService } from 'src/app/services/steps.service';
import { UtilsPublicService } from 'src/app/services/utils-public.service';
import { environment } from 'src/environments/environment';
import { Entrant } from 'src/interfaces/entrant';
import { Form } from 'src/interfaces/form';
import { Page } from 'src/interfaces/page';
import { PageBlock } from 'src/interfaces/pageBlock';
import { PrizeGame } from 'src/interfaces/prizeGame';
import { Response } from 'src/interfaces/response';
import { WindowApp } from 'src/interfaces/windowApp';

declare var window: WindowApp;

@Component({
    selector: 'app-page-block-form',
    templateUrl: './page-block-form.component.html',
    styleUrls: ['./page-block-form.component.scss']
})
export class PageBlockFormComponent implements OnInit {
    @Input() page!: Page | any;
    @Input() block!: PageBlock;
    @Input() preview?: boolean = false;
    @Input() skipGame?: boolean = false;
    @Input() entrantId?: string;
    @Input() entrant?: Entrant;
    @Input() database?: string;
    @ViewChild('questionStepper') stepper?: MatStepper;

    form!: Form;
    formGroup: FormGroup = new FormGroup({});
    stepperFormGroups: FormGroup[] = [];
    formValues: { [key: string]: string } = {};
    gameResults: boolean = false;
    game!: PrizeGame;
    gameHasWon: boolean = false;
    gamePreview: boolean = false;
    loading: boolean = true;
    blockRetry: boolean = false;
    hasRecaptcha: boolean = false;
    recaptchaToken: string = '';
    constructor(private Execute: ExecuteService, private Interact: InteractService, private route: ActivatedRoute, private UtilsPublic: UtilsPublicService, public HiddenIf: hiddenIfService, private Branding: BrandingService, private Steps: StepsService) {

    }

    ngOnInit(): void {

        if (this.UtilsPublic.isBrowser()) {
            window.startedAt = new Date();
            window.sdk.formGroup = this.formGroup;
            window.sdk.stepperFormGroups = this.stepperFormGroups;
            setTimeout(() => { window.sdk.events.trigger('formReady', true) }, 500);
            window.sdk.game.revealTrigger = () => { return this.revealedTrigger() };

            if (window.location.href.includes('preview=true') || window.location.pathname.includes('pagePreview')) {
                this.preview = true;
            }
        }

        if (this.block && this.block.component && 'questions' in this.block.component) {
            this.form = this.block.component;

            if (this.UtilsPublic.isBrowser()) {
                if (this.form.enabled.recaptchaV3) {
                    this.hasRecaptcha = true;
                    this.UtilsPublic.loadJS('https://www.google.com/recaptcha/api.js?render=' + environment.recaptchaV3);
                }
            }
            if (this.form.branding.showGamePreview) {
                this.setupGamePreview();
            }

            if (!this.form.type) {
                this.form.type = 'entry';
            }
            if (this.form.enabled.stepper) {
                this.setupStepper();
            }

            if (this.UtilsPublic.isBrowser()) {
                window.app.formId = this.form._id?.toString();
                window.app.pipelineId = this.form.pipeline;
            }

            if (!this.form.game) {
                this.form.game = { before: [], after: [] };
            }
        }
        this.checkIfAgeGate();

        this.formGroup.valueChanges.subscribe((formValues) => {
            this.formValues = formValues;
        });

        if (this.route.snapshot.queryParamMap.get('skipGame')) {
            this.skipGame = true;
        }

        if (this.form.type === 'entry' && (this.route.snapshot.queryParamMap.get('id') || this.entrantId && !this.route.snapshot.queryParamMap.get('skipGame'))) {
            this.setupResults();
        } else {
            this.loading = false;
        }

        if (!this.preview)
            this.Branding.setFormBranding(this.form);
    }


    setupStepper(): void {
        if (this.form.stepperQuestions) {
            if (this.preview) {
                this.form.stepperQuestions[0].tabLinear = false;
            }
            this.formValues = {};
            this.stepperFormGroups = [];
            for (const _stepperQuestions of this.form.stepperQuestions) {
                const formGroup = new FormGroup({});
                formGroup.valueChanges.subscribe((formValues: { [key: string]: any }) => {
                    for (const key in formValues) {
                        this.formValues[key] = formValues[key];
                    }
                });

                this.stepperFormGroups.push(formGroup);
            }
        }

        if (this.UtilsPublic.isBrowser()) {
            window.sdk.stepperFormGroups = this.stepperFormGroups;
            setTimeout(() => {
                window.sdk.stepper = this.stepper;
            }, 1000);
        }
    }

    async setupGamePreview(): Promise<void> {
        // you can't play the game so it might as well be a losing game
        this.gamePreview = true;



        this.gameHasWon = false;
        // this.game = stepData;
    }

    revealForm(): void {
        document.querySelector('.form-container')?.classList.remove('hidden');
        document.querySelector('.form-container')?.classList.add('fadeInOpacity');
        document.querySelector('app-prize-game')?.classList.add('shade-out');
    }

    async setupResults(): Promise<void> {
        if (this.UtilsPublic.isBrowser()) {
            try {
                this.gameResults = true;

                setTimeout(async () => {
                    try {

                        const response: Response | any = await firstValueFrom(this.UtilsPublic.http.get('/api/gameResults', { withCredentials: true }));
                        if (response && response.status === 'ok' && response.data) {
                            const result = response.data;
                            this.gameHasWon = response.data.status === 'win';

                            this.game = {
                                type: result.type,
                                options: {
                                    background: result.background,
                                    win: result.game,
                                    lose: result.game,
                                    goodLuck: result.goodLuck,
                                }
                            };
                        }
                    } catch (err) {
                        console.error(err);
                        this.Interact.displayDialog({});
                    }
                }, 500);
            } catch (err) {
                console.error(err);
                this.Interact.displayDialog({ title: 'Error!', content: 'Sorry, an error has occurred.' });
            }
        }
        this.loading = false;
    }

    checkIfAgeGate(): void {
        if (this.UtilsPublic.isBrowser() && this.preview === false) {
            if (this.form.type === 'ageGate') {
                const redirectOld = localStorage.getItem('ageGateRedirectOld');
                const redirectYoung = localStorage.getItem('ageGateRedirectYoung');

                if (this.form.branding.ageGateAutoRedirect || this.form.branding.ageGateAutoRedirectOld)
                    if (redirectOld)
                        return void this.UtilsPublic.redirect(redirectOld, true);

                if (this.form.branding.ageGateAutoRedirect || this.form.branding.ageGateAutoRedirectYoung)
                    if (redirectYoung)
                        return void this.UtilsPublic.redirect(redirectYoung, true);

                // if you hit the bottom, remove any potential redirects
                localStorage.removeItem('ageGateRedirectOld');
                localStorage.removeItem('ageGateRedirectYoung');
            }
            if (this.form.type === 'entry' && this.form.branding.ageGateRequired && this.form.branding.ageGateRequiredRedirect) {
                if (!localStorage.getItem('ageGateRedirectOld'))
                    return void this.UtilsPublic.redirect(this.form.branding.ageGateRequiredRedirect, true);
            }
        }
    }

    async submit(recaptchaDone: boolean = false): Promise<void> {
        if (this.blockRetry) {
            return void window.location.reload();
        }

        if (this.hasRecaptcha && recaptchaDone === false) {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(environment.recaptchaV3, { action: 'submit' }).then((token: string) => {
                    this.recaptchaToken = token;
                    this.submit(true);
                });
            });
            return;
        }


        if (this.form.type === 'ageGate' && this.isValid()) {  // different logic
            const dob = (this.form.stepperQuestions?.find(step => step.questions.find(question => question.type === 'dob'))?.questions || this.form.questions)?.find(question => question.type === 'dob');
            if (!this.form.branding.ageGateRedirectOk)
                return void this.Interact.displayDialog({ title: 'Age gate not configured correctly', content: 'Missing age gate redirect for old enough' });
            if (!this.form.branding.ageGateRedirectYoung)
                return void this.Interact.displayDialog({ title: 'Age gate not configured correctly', content: 'Missing age gate redirect for too young' });

            if (dob) {
                const value = this.formGroup.get(dob.key)?.value;
                if (value) {
                    if (dob.options.typeSpecificOptions.minimumAge) {
                        const dateOfBirth = moment(value).utc(true);
                        const year = dateOfBirth.year();
                        const month = dateOfBirth.month() + 1;
                        const day = dateOfBirth.date();
                        if (this.UtilsPublic.dateIsOldEnough((window.minimumAge || dob.options.typeSpecificOptions.minimumAge), { year, month, day })) {
                            localStorage.setItem('ageGateRedirectOld', this.form.branding.ageGateRedirectOk);
                            this.UtilsPublic.redirect(this.form.branding.ageGateRedirectOk, true)
                        } else {
                            localStorage.setItem('ageGateRedirectYoung', this.form.branding.ageGateRedirectYoung);
                            this.UtilsPublic.redirect(this.form.branding.ageGateRedirectYoung, true);
                        }
                    } else {
                        localStorage.setItem('ageGateRedirectOld', this.form.branding.ageGateRedirectOk);
                        this.UtilsPublic.redirect(this.form.branding.ageGateRedirectOk, true);
                    }
                }
            } else {
                this.Interact.displayDialog({ title: 'No Date of birth question', content: 'Could not find a date of birth question' });
            }


        } else if (this.isValid()) {
            setTimeout(() => { window.sdk.events.trigger('formSubmitValid') }, 0); // event

            this.Interact.displayLoading({ total: 1 });

            const entrant: Entrant = await this.Execute.executeEntrantModifiers({
                metadata: {
                    status: 'valid',
                    origin: 'website',
                    promotionIds: this.form.promotionIds || [],
                    url: window.location.href,
                    triggers: [],
                    formName: this.form.name,
                    formId: this.form._id.toString(),
                    pageId: this.page._id.toString(),
                    platform: JSON.parse(JSON.stringify(window.platform || {})),
                    ip: '',
                    cfIp: '',
                },
                answers: this.formValues,
                startedAt: new Date(window.startedAt).toISOString() as any,
                finishedAt: new Date()
            });

            if (this.hasRecaptcha) {
                entrant.metadata.recaptchaToken = this.recaptchaToken;
            }

            const data: any = { entrant };
            if (this.form.type === 'claim') {
                data.claimId = this.route.snapshot.queryParamMap.get('id');
            }

            const response = await this.Execute.run({ pipelineId: this.form.pipeline, data, reason: 'formSubmit', variablesMode: false });
            this.Interact.dialog.closeAll();
            window.sdk.events.trigger('loading-finish');


            if (response && response.message && response.message.toString().indexOf('rate limited') !== -1) {
                setTimeout(() => { this.Interact.displayDialog({ title: this.form.branding.defaultDialogTitle || 'Error!', content: this.form.branding.messageRateLimited }) }, 500);
                return;
            }

            if (response && response.message && response.message.includes('human verification')) {
                this.blockRetry = true;
                setTimeout(() => { this.formGroup.reset(); }, 500);
                setTimeout(() => { window.location.reload() }, 2000);
            }

            if (response.status === 'ok' && response.data && response.data.actions) {
                window.sdk.events.trigger('formSubmitSuccess'); // event
                const actions = response.data.actions;
                setTimeout(async () => { // wait 250ms to let the loading icon go away
                    this.Execute.actions(this, actions);
                }, 250);

            } else if (response.message) {
                window.sdk.events.trigger('formSubmitError'); // event
                this.Interact.displayDialog({ title: this.form.branding.defaultDialogTitle || 'Error!', content: response.message.toString() });
            }

        } else {
            setTimeout(() => { window.sdk.events.trigger('formSubmitFail') }, 0); // event
            try {
                if (this.form.enabled.stepper) {
                    this.stepperFormGroups.map(formGroup => {
                        formGroup.markAllAsTouched();
                        for (const controlName in formGroup.controls) {
                            this.formGroup.get(controlName)?.markAsDirty();
                        }
                    });
                } else {
                    this.formGroup.markAllAsTouched();
                    for (const controlName in this.formGroup.controls) {
                        this.formGroup.get(controlName)?.markAsDirty();
                    }
                }
            } catch (err) { }
        }
    }

    revealedTrigger(): Function {
        return async () => {
            const response = await this.Execute.run({ pipelineId: this.form.pipeline, data: { gameHasWon: this.gameHasWon, entrantId: this.route.snapshot.queryParamMap.get('id') as string }, trigger: 'prizeReveal', reason: 'prizeReveal', variablesMode: false });
            const actions = response.data.actions;

            await this.Execute.actions(this, actions);
        };
    }
    canSubmit(): boolean {
        if (this.form.branding.allowSubmitWhileInvalid) { // if they've toggled the override to allow pressing the submit button while invalid
            return true;
        }
        return this.isValid();
    }

    isValid(): boolean {
        if (this.form.enabled.stepper) {
            return !this.stepperFormGroups.find(formGroup => formGroup.invalid);
        } else {
            return this.formGroup.valid;
        }
    }

    shouldShowNextButton(idx: number): boolean {
        return !!(this.form.stepperQuestions && idx !== (this.form.stepperQuestions.length - 1));
    }
    shouldShowSubmitButton(idx: number): boolean {
        return !!(this.form.stepperQuestions && idx === (this.form.stepperQuestions.length - 1));
    }
    shouldShowPreviousButton(idx: number): boolean {
        return !!(this.form.stepperQuestions && idx !== 0);
    }
}
