<mat-card class="tap-container">
    <div class="pyro" style="display:none;" *ngIf="hasWon && !isAdmin && !demo">
        <div class="before"></div>
        <div class="after"></div>
    </div>

    <div class="tap-prize">
        <div class="tap-inner">
            <img [src]="hasWon ? game.options && game.options.win && game.options.win.image : game.options.lose.image">
            <p class="tap-message">{{hasWon ? game.options && game.options.win && game.options.win.message : game.options.lose.message}}</p>
        </div>
    </div>
    <div class="tap-background">
        <div class="tap-inner">
            <img [src]="game.options.background">
            <p class="tap-message">&nbsp;</p>
        </div>
    </div>

    <div class="tap-shadow">&nbsp;</div>

    <div class="clear"></div>
</mat-card>