<div class="container">
    <div class="pyro" style="display:none;" *ngIf="hasWon && !isAdmin && !demo">
        <div class="before"></div>
        <div class="after"></div>
    </div>

    <section class="featured-card">
        <img class="{{hasDealt ? 'real-card': 'placeholder-card'}}" [src]="dealtCards[dealtCards.length - 1] ? deckURL + '/' + dealtCards[dealtCards.length - 1].suit + '-' + dealtCards[dealtCards.length - 1].card + '.png' : deckURL + '/back.png'">

    </section>
    <section class="controls">
        <button *ngIf="!hasDealt" mat-raised-button color="accent" (click)="deal()">Play</button>
        <button *ngIf="showControls && hasDealt" mat-raised-button color="accent" (click)="pickHigh()">Higher</button>
        <button *ngIf="showControls && hasDealt" mat-raised-button color="accent" (click)="pickLow()">Lower</button>
    </section>
    <section class="deck">
        <div class="card {{dealtCards[cardIdx] ? 'deck-card': ''}} {{!showControls && !hasWon && (dealtCards.length - 1) === idx ? 'last-card':''}}" *ngFor="let cardIdx of backOfCards;let idx = index;">
            <img class="{{dealtCards[cardIdx] ? 'real-card': 'placeholder-card'}}" [src]="dealtCards[cardIdx] ? deckURL + '/' + dealtCards[cardIdx].suit + '-' + dealtCards[cardIdx].card + '.png' :deckURL+'/back.png'">
        </div>

    </section>
    <section>
        <div *ngIf="showControls && hasDealt" class="progress">{{dealtCards.length}}/5</div>

        <div *ngIf="!showControls && hasWon" class="message">{{game.options.win.message}}</div>
        <div *ngIf="!showControls && !hasWon" class="message">{{game.options.lose.message}}</div>
    </section>
</div>