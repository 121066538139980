<mat-card class="slider-container">
    <div class="pyro" style="display:none;" *ngIf="hasWon && !isAdmin && !demo">
        <div class="before"></div>
        <div class="after"></div>
    </div>

    <div class="slider-prize">
        <div class="slider-inner">
            <img [src]="hasWon ? game.options && game.options.win && game.options.win.image : game.options.lose.image">
            <p class="slider-message">{{hasWon ? game.options && game.options.win && game.options.win.message : game.options.lose.message}}</p>
        </div>
    </div>
    <div class="slider-background">
        <div class="slider-control" cdkDragBoundary=".slider-background" cdkDrag cdkDragLockAxis="x">
            <mat-icon>dehaze</mat-icon>
        </div>
        <div class="slider-inner">
            <img [src]="game.options.background">
            <p class="slider-message">&nbsp;</p>
        </div>
    </div>

    <div class="slider-shadow">&nbsp;</div>

    <div class="clear"></div>
</mat-card>