<section [formGroup]="formGroup">
    <mat-form-field [appearance]="Validation.getAppearance(question)" [hideRequiredMarker]="Validation.getHideRequired(question)" [floatLabel]="Validation.getFloatLabel(question)">
        <mat-label>{{question.label}}</mat-label>
        <img *ngIf="selectedValue && selectedValue.image" matPrefix [src]="selectedValue.image">
        <mat-select [formControlName]="controlName" [placeholder]="Validation.getPlaceholder(question)">
            <mat-option *ngFor="let value of question.options.values" [value]="value.value" [disabled]="value.disabled || !controller?.isAnswerVisible(question, value)">
           
                <img class="autocomplete-image" *ngIf="value.image" alt="" [src]="value.image">
                <mat-icon class="autocomplete-icon" *ngIf="value.icon">{{value.icon}}</mat-icon>
                <span class="autocomplete-label" *ngIf="value.label">{{value.label}}</span>
            </mat-option>
        </mat-select>

        <mat-hint *ngIf="question.options.branding?.hint">{{question.options.branding?.hint}}</mat-hint>

        <mat-error *ngIf="control && control.hasError('required')">
            <span [innerHTML]="Validation.getRequiredMessage(question)">&nbsp;</span>
        </mat-error>
    </mat-form-field>
</section>