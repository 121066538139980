import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

declare var window: Window;

@Component({
    selector: 'app-interact-prompt',
    templateUrl: './interact-prompt.component.html',
    styleUrls: ['./interact-prompt.component.scss']
})
export class InteractPromptComponent implements OnInit {
    dropdownData?: { label: string, value: string }[];
    constructor(public dialogRef: MatDialogRef<InteractPromptComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

    input?: string;
    option: string = '';

    ngOnInit() {
        if (this.data && 'dropdownOptions' in this.data && this.data.dropdownOptions && this.data.dropdownOptions.length) {
            this.dropdownData = this.data.dropdownOptions;
        }
        if (this.data && 'defaultVal' in this.data && this.data.defaultVal) {
            this.input = this.data.defaultVal;
        }

        setTimeout(() => {
            if (window.document.getElementById("prompt-input")) {
                const prompt = window.document.getElementById("prompt-input") as any;
                prompt?.focus();
                prompt?.select();
            }
        }, 250)
    }

    close(returnVal: boolean = false): void {
        if (returnVal === true) {
            if (this.data && 'successCallback' in this.data && this.data['successCallback'] && typeof this.data['successCallback'] === 'function') {
                this.data['successCallback'](this.input, this.option);
            }
        }

        this.dialogRef.close();
    }

    keypress(event: any): void {
        if (event.key === 'Enter') {
            this.close(true);
        }
    }

}
