import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, startWith, map } from 'rxjs';
import { ValidationService } from 'src/app/services/validation.service';
import { Entrant } from 'src/interfaces/entrant';
import { Question } from 'src/interfaces/question';
import { QuestionValue } from 'src/interfaces/questionValues';
import { PageBlockFormQuestionComponent } from '../page-block-form-question.component';

@Component({
    selector: 'app-page-block-form-question-autocomplete',
    templateUrl: './page-block-form-question-autocomplete.component.html',
    styleUrls: ['./page-block-form-question-autocomplete.component.scss']
})
export class PageBlockFormQuestionAutocompleteComponent implements OnInit {

    @Input() formGroup!: FormGroup;
    @Input() question!: Question;
    @Input() entrant?: Entrant;
    @Input() controller?: PageBlockFormQuestionComponent;

    controlName: string = this.question?.key;
    control: FormControl = this.formBuilder?.control('', []);
    filterValue: string = '';
    originalAnswers: QuestionValue[] = [];
    filteredAnswers: QuestionValue[] = [];
    selectedValue?: QuestionValue;

    constructor(private formBuilder: FormBuilder, public Validation: ValidationService) {
    }

    ngOnInit(): void {
        this.controlName = this.question.key;
        this.filteredAnswers = this.question.options.values || [];

        this.setValidation();
        this.Validation.setCustomValidation(this.question, this.question.options.validations, this.control);
    }

    setValidation(): void {
        this.originalAnswers = JSON.parse(JSON.stringify(this.question.options.values));

        if (this.question.options.required) {
            try { // some values will not regex so just ignore
                this.control.addValidators(Validators.pattern(new RegExp((this.question.options.values?.map((option) => '^' + option.value + '$').join('|').replace(/\+/g, '\\+')) || '')));
            } catch (err) {
                console.error(err);
            }
        }

        this.control.valueChanges.subscribe((value) => {
            this.filteredAnswers = this.filterAnswers(value);
            const selected = this.question.options.values?.find(option => option.value === value);
            this.selectedValue = selected;
        });

        this.formGroup.addControl(this.controlName, this.control);



        setTimeout(() => {
            if (this.entrant && this.entrant?.answers && this.entrant?.answers[this.question.key]) {
                this.control.setValue(this.entrant?.answers[this.question.key]);
            } else {
                if (this.question.options.values?.find(option => option.default === true)) {
                    const defaultOption = this.question.options.values.find(option => option.default === true);
                    if (defaultOption?.value) {
                        this.control.setValue(defaultOption?.value);
                    }
                }
            }
        }, 250);
    }

    reset(): void {
        this.filteredAnswers = JSON.parse(JSON.stringify(this.originalAnswers));
        this.control.reset();
    }

    scopedDisplayFn(): any {
        return (selectedValue: string) => {
            if (this.question && this.question.options && this.question.options.values) {
                const selected = this.question.options.values?.find(option => option.value === selectedValue);
                this.selectedValue = selected;


                return selected?.label || '';
            } else {
                this.selectedValue = undefined;
                return '';
            }
        }
    }

    public filterAnswers(value: string): QuestionValue[] {
        if (value) {

            const filterValue = value.toLowerCase();

            return this.question.options.values?.filter(option => option.label.toLowerCase().includes(filterValue) || option.value.toLowerCase().includes(filterValue)) || this.question.options.values as QuestionValue[];
        } else {
            return this.question.options.values as QuestionValue[];
        }
    }

    ngOnDestroy(): void {
        this.formGroup.removeControl(this.controlName);
    }

}
