import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { UtilsPublicService } from 'src/app/services/utils-public.service';

@Component({
    selector: 'app-button-happy',
    templateUrl: './button-happy.component.html',
    styleUrls: ['./button-happy.component.scss']
})
export class ButtonHappyComponent implements OnInit {
    @ViewChild('button') button!: MatButton;
    @Input() color: string = 'primary';
    @Input() icon?: string = '';
    @Input() firstLine: string = 'Example';
    @Input() secondLine: string = 'Question';
    @Input() link?: string; // not yet supported
    @Input() disabled: boolean = false;
    @Input() selected: boolean = false;
    @Input() class: string = '';
    @Input() attention: boolean = false;
    @Input() href: string = '';

    constructor(private UtilsPublic: UtilsPublicService) { }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        if (this.href) {
            this.button._elementRef.nativeElement.addEventListener('click', () => { this.UtilsPublic.redirect(this.href); });
        }
    }
    getCssClass() {
        let css = 'happy-button mat-stroked-button ';
        if (this.selected)
            css += ' selected ';

        css += ' ' + this.color + ' ';
        css += ' ' + this.class + ' ';
        if (this.secondLine)
            css += ' secondLine ';

        if (this.attention)
            css += ' attention ';

        return css;
    }
}
