<section [formGroup]="formGroup" (click)="question.options.typeSpecificOptions.pickerOnly ? picker.open() : null">
    <mat-form-field class="" [appearance]="Validation.getAppearance(question)" [hideRequiredMarker]="Validation.getHideRequired(question)" [floatLabel]="Validation.getFloatLabel(question)">
        <mat-label>{{question.label}}</mat-label>

        <input matInput [matDatepicker]="picker" class="datepicker-{{controlName}}" [formControlName]="controlName" [min]="minDate" [max]="maxDate" [placeholder]="Validation.getPlaceholder(question)">
        <div class="overlay datepicker-{{controlName}}-input-overlay"></div>

        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker [touchUi]="isMobile()" #picker></mat-datepicker>

        <mat-hint *ngIf="question.options.branding?.hint">{{question.options.branding?.hint}}</mat-hint>

        <mat-error *ngIf="control && control.hasError('required')">
            <span [innerHTML]="Validation.getRequiredMessage(question)">&nbsp;</span>
        </mat-error>
        <mat-error *ngIf="control && control.hasError('matDatepickerMin') && control.dirty" [innerHTML]="Validation.getCustomErrorMessage(question, 'minDateMessage', 'Please ensure the date entered is correct')">&nbsp;</mat-error>
        <mat-error *ngIf="control && control.hasError('matDatepickerMax') && control.dirty" [innerHTML]="Validation.getCustomErrorMessage(question, 'maxDateMessage', 'Please ensure the date entered is correct')">&nbsp;</mat-error>
    </mat-form-field>
</section>