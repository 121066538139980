<section [formGroup]="formGroup">
    <mat-label class="question.label.length ? 'label' : 'empty-label'">{{ question.label }}</mat-label>
    <div id="key-{{question.key}}-mask-containter" formArrayName="maskInputs" class="input-mask">
        <div *ngFor="let control of inputsArray.controls; let i = index" id="key-{{question.key}}-{{ i }}" class="input-container" [style.width]="getFieldWidth()">
            <mat-form-field [appearance]="Validation.getAppearance(question)" [floatLabel]="Validation.getFloatLabel(question)">
                <input matInput type="text" maxlength="{{ maxLength }}" [formControlName]="i" (input)="onInputChange(i, $event)" (keydown.backspace)="onBackspace(i, $event)" (paste)="onPaste($event)" [id]="'input-' + i" />
            </mat-form-field>
        </div>
    </div>    
    <mat-form-field class="hide-input">
        <input [formControlName]="controlName" matInput type="text">
        <mat-error *ngIf="control && control.hasError('required') && (control.dirty || control.touched)">
            <span [innerHTML]="Validation.getRequiredMessage(question)">&nbsp;</span>
        </mat-error>
        <mat-error *ngFor="let error of Validation.getCustomValidationErrors(question, control)">
            {{error}}
        </mat-error>
    </mat-form-field>
</section>
