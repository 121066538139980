<div *ngIf="data">
    <h1 mat-dialog-title>{{data.title}}</h1>
    <div class="content" mat-dialog-content>
        {{data.content}}
    </div>
    <br />
    <div mat-dialog-actions>
        <button mat-raised-button class="cancel" color="warn" mat-dialog-close (click)="cancel()">Cancel</button>
        <span class="spacer"></span>
        <button mat-raised-button autofocus cdkFocusInitial color="primary" (click)="success()" mat-dialog-close>Ok</button>
    </div>
</div>