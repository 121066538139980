import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    { path: 'admin', loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule) },
    { path: 'token', loadChildren: () => import('./pages/token/token.module').then(m => m.TokenModule) },
    { path: '404', loadChildren: () => import('./pages/notfound/notfound.module').then(m => m.NotFoundModule) },
    { path: 'games', loadChildren: () => import('./pages/games/games.module').then(m => m.GamesModule) },
    { path: 'pagePreview', loadChildren: () => import('./pages/navigator/navigator.module').then(m => m.NavigatorModule) },
    { path: '**', loadChildren: () => import('./pages/navigator/navigator.module').then(m => m.NavigatorModule) },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })],
    exports: [RouterModule]
})
export class AppRoutingModule { 
    routes: Routes = routes;
}
