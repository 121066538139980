<div *ngIf="data">
    <h1 mat-dialog-title>{{data.title}}</h1>
    <div class="content" mat-dialog-content>
        {{data.content}}
    </div>
    <br />
    <div mat-dialog-actions>
        <div class="buttons" *ngFor="let button of data.buttons">
            <button mat-raised-button color="{{button.color}}" (click)="callback(button.callback)">{{button.label}}</button>
            <span class="spacer"></span>
        </div>
    </div>
</div>