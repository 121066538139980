import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { Entrant } from 'src/interfaces/entrant';
import { PageBlockFormQuestion } from 'src/interfaces/pageBlockFormQuestion';
import { Question } from 'src/interfaces/question';
import { PageBlockFormQuestionComponent } from '../page-block-form-question.component';
import { QuestionValue } from 'src/interfaces/questionValues';
import { UtilsPublicService } from 'src/app/services/utils-public.service';

@Component({
    selector: 'app-page-block-form-question-radio',
    templateUrl: './page-block-form-question-radio.component.html',
    styleUrls: ['./page-block-form-question-radio.component.scss']
})
export class PageBlockFormQuestionRadioComponent implements OnInit {
    @Input() formGroup!: FormGroup;
    @Input() question!: Question;
    @Input() entrant?: Entrant;
    @Input() controller?: PageBlockFormQuestionComponent;

    controlName: string = this.question?.key;
    control: FormControl = this.formBuilder?.control('', []);
    selectedValue?: QuestionValue;

    constructor(private formBuilder: FormBuilder, public Validation: ValidationService, private UtilsPublic: UtilsPublicService) {
    }

    ngOnInit(): void {
        this.controlName = this.question.key;

        this.setValidation();
        this.Validation.setCustomValidation(this.question, this.question.options.validations, this.control);
        this.setBranding();
    }

    setValidation(): void {
        this.formGroup.addControl(this.controlName, this.control);
        this.control.valueChanges.subscribe(selectedValue => {
            const selected = this.question.options.values?.find(option => option.value === selectedValue);
            this.selectedValue = selected;
        });

        setTimeout(() => {
            if (this.entrant && this.entrant?.answers && this.entrant?.answers[this.question.key]) {
                this.control.setValue(this.entrant?.answers[this.question.key]);
            } else {
                if (this.question.options.values?.find(option => option.default === true)) {
                    const defaultOption = this.question.options.values.find(option => option.default === true);
                    if (defaultOption?.value) {
                        this.control.setValue(defaultOption?.value);
                    }
                }
            }
        }, 250);
    }


    setBranding(): void {
        if (this.question.options.typeSpecificOptions && this.question.options.typeSpecificOptions.backgroundColour && this.question.options.typeSpecificOptions.tickColour) {

            const css = `

            .key-${this.question.key} .mat-radio-button.mat-accent .mat-radio-inner-circle, 
            .key-${this.question.key} .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), 
            .key-${this.question.key} .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, 
            .key-${this.question.key} .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
                background-color: ${this.question.options.typeSpecificOptions.tickColour};
            
            }
            .key-${this.question.key} .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
                border-color: ${this.question.options.typeSpecificOptions.backgroundColour};
            }

            `;
            this.UtilsPublic.loadCSSInline(css, this.question.key + '-radio-colour');
        }
    }

    ngOnDestroy(): void {
        this.formGroup.removeControl(this.controlName);
        this.UtilsPublic.loadCSSInline('', this.question.key + '-radio-colour');
    }
}
