import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-interact-confirm-buttons',
    templateUrl: './interact-confirm-buttons.component.html',
    styleUrls: ['./interact-confirm-buttons.component.scss']
})
export class InteractConfirmButtonsComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string, content: string, buttons: { label: string, color: ThemePalette, callback: Function }[] }, public dialog: MatDialog, public dialogRef: MatDialogRef<InteractConfirmButtonsComponent>) {}

    ngOnInit(): void {}

    callback(callback: Function) {
        callback(this.dialogRef);
    }
}
