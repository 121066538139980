import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Step } from '../../interfaces/pipelineStep';

@Injectable({
    providedIn: 'root'
})
export class StepsService {

    constructor(private http: HttpClient) {
    }
    async getStepOutOfContext(pipelineId: string, stepId: string, entrantId: string, database?: string): Promise<object | null> {
        let url = '/api/steps?pipelineId=' + pipelineId + '&pipelineStepId=' + stepId + '&entrantId=' + entrantId;

        if (database) {
            url += '&database=' + database;
        }

        const siteResponse: Response | any = await firstValueFrom(this.http.get(url, { withCredentials: true }));
        if (siteResponse && siteResponse.status === 'ok' && siteResponse.data) {
            return siteResponse.data;
        }
        return null;
    }

    async getStepOutOfContextByType(pipelineId: string, stepType: string): Promise<object | null> {

        const siteResponse: Response | any = await firstValueFrom(this.http.get('/api/steps?pipelineId=' + pipelineId + '&stepType=' + stepType, { withCredentials: true }));
        if (siteResponse && siteResponse.status === 'ok' && siteResponse.data) {
            return siteResponse.data;
        }
        return null;
    }

    hasStepType(pipeline: Step[], type: string): boolean {
        for (const step of pipeline) {
            if (step.type && step.type === type) {
                return true;
            }
        }
        return false;
    }

    getStepType(pipeline: Step[], type: string): Step | null {
        for (const step of pipeline) {
            if (step.type && step.type === type) {
                return step;
            }
        }
        return null;
    }

    getStepByStepId(pipeline: Step[], stepId: string): Step | null {
        for (const step of pipeline) {
            if (step.id === stepId) {
                return step;
            }
        }

        return null;
    }
}
