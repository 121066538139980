import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-interact-confirm',
    templateUrl: './interact-confirm.component.html',
    styleUrls: ['./interact-confirm.component.scss']
})
export class InteractConfirmComponent implements OnInit {
    public dialogRef!: MatDialogRef<InteractConfirmComponent>;

    constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string, content: string, successCallback: Function, dialogRef: DialogRef, cancelCallback: Function }, public dialog: MatDialog) { }
    successCallback: Function = () => { };
    cancelCallback: Function = () => { };

    ngOnInit(): void {
        this.successCallback = () => { };
        this.cancelCallback = () => { };

        if (this.data && 'successCallback' in this.data && this.data['successCallback'] && typeof this.data['successCallback']) {
            this.successCallback = this.data['successCallback'];
        }
        if (this.data && 'cancelCallback' in this.data && this.data['cancelCallback'] && typeof this.data['cancelCallback']) {
            this.cancelCallback = this.data['cancelCallback'];
        }
    }
    success(): void {
        if (this.successCallback)
            this.successCallback()
    }

    cancel(): void {
        if (this.cancelCallback)
            this.cancelCallback()
    }
}
