import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { PrizeGame } from 'src/interfaces/prizeGame';
import { PrizeGamesTypes } from 'src/interfaces/prizeGamesTypes';

@Component({
    selector: 'app-prize-game-slide-reveal',
    templateUrl: './prize-game-slide-reveal.component.html',
    styleUrls: ['./prize-game-slide-reveal.component.scss']
})
export class PrizeGameSlideRevealComponent implements OnInit, OnDestroy {

    @Input() type!: keyof PrizeGamesTypes | string;
    @Input() hasWon!: boolean;
    @Input() game!: PrizeGame;
    @Input() revealedTrigger!: Function;
    @Input() isAdmin!: boolean;
    @Input() demo!: boolean;

    revealInterval: any;
    sentTrigger: boolean = false;

    constructor(@Inject(DOCUMENT) private document: Document) { }

    ngOnInit(): void {

        setTimeout(() => {
            this.setup();
        }, 250);

    }

    setup(): void {
        const control: HTMLElement | null = this.document.querySelector('.slider-control');
        const background: HTMLElement | null = this.document.querySelector('.slider-background');
        const shadow: HTMLElement | null = this.document.querySelector('.slider-shadow');

        if (!background?.offsetHeight || background?.offsetHeight < 300 || !control) {
            setTimeout(() => {
                this.setup();
            }, 500);
            return;
        }

        if (control?.style)
            control.style.height = background?.offsetHeight + 'px';

        if (shadow?.style) {
            shadow.style.height = background?.offsetHeight + 'px';
            shadow.style.width = background?.offsetWidth + 'px';
        }

        if (typeof window === 'object') {
            this.revealInterval = setInterval(() => {
                const prize: HTMLElement | null = this.document.querySelector('.slider-prize'); // at this point it must be here!
                let hasRevealed: boolean = false;


                let position = 0;
                try {

                    const translated = (this.document.querySelector('.slider-control') as HTMLElement).style.transform; // translate3d(64px, 0px, 0px) - X Y Z
                    position = parseInt(translated.replace('translate3d(', '').replace(')', '').split(',')[0].replace('px', ''));

                    position = position - 15; // the line's width


                    hasRevealed = ((position / background?.offsetWidth) * 100) >= 90;

                    if (this.document.querySelector('.pyro') && !this.demo)
                        (this.document.querySelector('.pyro') as HTMLElement).style.display = (this.hasWon && hasRevealed ? 'block' : 'none');

                    if (hasRevealed && !this.sentTrigger && this.revealedTrigger) {
                        this.sentTrigger = true;
                        this.revealedTrigger();
                    }

                    if (position < 0) {
                        position = 0;
                    }
                } catch (err) {
                    position = 0;
                }

                if (prize && prize?.style.width !== (position + 'px'))
                    prize.style.width = (position + 'px');
            }, 25);
        }
    }
    ngOnDestroy(): void {
        clearInterval(this.revealInterval);
    }
}
