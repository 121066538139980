<section [formGroup]="formGroup" *ngIf="options">
    <mat-label>{{question.label}}</mat-label>
    <br />
    <div class="slider {{showVertical() ? 'vertical' : 'horizontal'}} {{showInverted() ? 'inverted' : ''}}">
        <div class="slider-labels" *ngIf="UtilsPublic.isMobile() === false && options.labelPosition === 'top' || (UtilsPublic.isMobile() && options.labelPositionPhone === 'top')">
            <label *ngFor="let option of options.labels" class="slider-label">{{option.label}}</label>
        </div>
        <div class="slider-container">
            <mat-slider [thumbLabel]="!!options.displayLabel" [min]="options.minLength" [max]="options.maxLength" [vertical]="showVertical()" [invert]="showInverted()" [tickInterval]="options.tickInterval || 'auto'" [formControlName]="controlName"
                [displayWith]="display()"></mat-slider>
        </div>
        <div class="slider-labels" *ngIf="UtilsPublic.isMobile() === false && options.labelPosition === 'bottom' || (UtilsPublic.isMobile() && options.labelPositionPhone === 'bottom')">
            <label *ngFor="let option of options.labels" class="slider-label">{{option.label}}</label>
        </div>
    </div>
    <mat-error *ngIf="control && control.hasError('required') && control.dirty">
        <span [innerHTML]="Validation.getRequiredMessage(question)">&nbsp;</span>
    </mat-error>
</section>