import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { SiteSettings } from 'src/interfaces/siteSettings';
import { environment } from '../../environments/environment';
import { WindowApp } from 'src/interfaces/windowApp';
import { Clipboard } from '@angular/cdk/clipboard';

declare var window: WindowApp & Window;

@Injectable({
    providedIn: 'root'
})
export class UtilsPublicService {
    // Public version of UtilsService
    constructor(@Inject(DOCUMENT) public document: Document, @Inject(PLATFORM_ID) private platformId: Object, @Optional() @Inject('siteSettings') public siteSettings: SiteSettings, public http: HttpClient, public Clipboard: Clipboard) {

    }

    isBrowser(): boolean {
        return !!isPlatformBrowser(this.platformId) && typeof window === 'object' && typeof window.document === 'object';
    }

    isMobile(): boolean {
        return this.isBrowser() && window.screen.width <= 500;
    }
    
    redirect(url: string, keepQueryString: boolean = false): void {
        if (this.isBrowser()) {
            console.info('Redirecting to: ' + url);
            if (keepQueryString)
                url = this.urlAddQueryString(url, window.location.search);

            if (url !== window.location.href && url !== (window.location.pathname + window.location.search)) // avoid looping
                window.location.href = url;
        }
    }

    serverUrl(path: string): string {
        if (this.isBrowser()) {
            return path;
        } else {
            if (this.siteSettings && this.siteSettings.domain) {
                const scheme = environment.mode === 'development' ? 'http://' : 'https://';
                return scheme + this.siteSettings.domain + path;
            } else {
                return path;
            }
        }
    }

    copyToClipboard(text: string): void {
        this.Clipboard.copy(text);
    }

    loadCSS(stylesheet: string, id?: string): UtilsPublicService {
        const css = this.document.createElement('link');
        css.setAttribute('rel', 'stylesheet');
        css.setAttribute('type', 'text/css');
        css.setAttribute('class', 'brand-css');
        css.setAttribute('href', stylesheet);
        if (id) {
            this.document.getElementById(id)?.remove();
            css.setAttribute('id', id);
        }

        this.document.head.appendChild(css);

        return this;
    }
    loadJS(javascript: string, async: boolean = false): UtilsPublicService {
        const script = this.document.createElement('script');
        script.src = javascript;
        script.setAttribute('class', 'brand-js');

        if (async)
            script.setAttribute('async', '');

        this.document.head.appendChild(script);

        return this;
    }

    loadCSSInline(style: string, id?: string): void {
        const css = this.document.createElement('style');
        id = id || 'customCSS';
        this.document.getElementById(id)?.remove();
        css.setAttribute('id', id);

        css.innerHTML = style;
        this.document.head.appendChild(css);
    }

    loadJSInline(script: string, id?: string): void {
        const javascript = this.document.createElement('script');
        javascript.setAttribute('id', id || 'customJS');
        javascript.innerHTML = script;
        this.document.head.appendChild(javascript);
    }

    shuffle(array: Array<any>): Array<any> {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex != 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }
    arraySortByKey<T>(array: T[], key: keyof T, direction: 'asc' | 'desc' = 'asc'): T[] {
        return array.sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }
    dateIsValid(date: { year: number, month: number, day: number }): boolean {
        const { year, month, day } = date;
        const isLeapYear = (year: number) => (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);

        // Ensure month is between 1 and 12 
        if (month < 1 || month > 12) {
            return false;
        }

        // Get the last day of the month
        let lastDay;
        if (month === 2 && isLeapYear(year)) { // 0 based, Feb is value 1
            lastDay = 29; // February in a leap year
        } else {
            lastDay = new Date(year, month, 0).getDate();
        }
        // Check if the provided day is within the valid range
        if (day < 1 || day > lastDay) {
            return false;
        }

        return true;
    }

    dateIsOldEnough(age: number, dob: { year: number, month: number, day: number }): boolean {
        const { year, month, day } = dob;

        const currentYear = new Date().getFullYear();
        const requiredYear = currentYear - age;
        const mustBeBornBefore = new Date(new Date().setFullYear(requiredYear));
        const bornDate = new Date(year, (month - 1), day);

        return bornDate <= mustBeBornBefore;
    }

    urlAddQueryString(url: string, queryString?: string): string {
        if (queryString) {
            return url.includes('?') ? (url + queryString.replace('?', '&')) : (url + queryString);
        }
        return url;
    }
    convertTimeToUTCTime(time: string): string {
        // time will look like this 12:34
        const [hours, minutes] = time.split(':');
        const localDate = new Date();
        localDate.setHours(parseInt(hours, 10), parseInt(minutes, 10));
        localDate.setSeconds(0);
        return localDate.toUTCString().split(' ')[4];
    }
}