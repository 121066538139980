<div *ngIf="data">
    <h1 mat-dialog-title>{{data.title}}</h1>
    <p>{{data.content}}</p>

    <div *ngIf="dropdownData">
        <mat-form-field appearance="outline" color="accent">
            <mat-label>Please select an option</mat-label>
            <mat-select [(ngModel)]="option">
                <mat-option *ngFor="let option of dropdownData" [value]="option.value">
                    {{option.label || option.value}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <mat-form-field appearance="outline" *ngIf="data.questionContent || option.toLowerCase() === 'other'" color="accent">
        <mat-label>{{data.questionContent}}</mat-label>
        <input id="prompt-input" autocomplete="off" autofocus="autofocus" (keypress)="keypress($event)" matInput [(ngModel)]="input" [ngModelOptions]="{standalone:true}">
    </mat-form-field>

    <div mat-dialog-actions>
        <button mat-raised-button color="warn" (click)="close()">Cancel</button>
        <span class="spacer"></span>
        <button mat-raised-button (click)="close(true)" autofocus cdkFocusInitial color="primary">Ok</button>
    </div>
</div>