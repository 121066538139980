<section [formGroup]="formGroup" class="{{control.dirty && control.invalid || question.options.dirty ? 'invalid': 'valid'}}">
    <mat-label class="{{question.label.length ? 'label': 'empty-label'}}">{{question.label}}</mat-label>

    <mat-checkbox (change)="modelChange()" *ngFor="let value of question.options.values" [value]="value.value" [disabled]="value.disabled || !controller?.isAnswerVisible(question, value)" [(ngModel)]="selected[value.value]" [ngModelOptions]="{standalone: true}">
        <span [innerHTML]="value.label"></span>
    </mat-checkbox>

    <mat-form-field appearance="outline" class="always-hidden">
        <input type="text" matInput [formControlName]="controlName">
    </mat-form-field>

    <mat-error *ngIf="control && control.hasError('required') && control.dirty">
        <span [innerHTML]="Validation.getRequiredMessage(question)">&nbsp;</span>
    </mat-error>
</section>