import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { Entrant } from 'src/interfaces/entrant';
import { Question } from 'src/interfaces/question';
import { QuestionValue } from 'src/interfaces/questionValues';
import { PageBlockFormQuestionComponent } from '../page-block-form-question.component';

@Component({
    selector: 'app-page-block-form-question-select',
    templateUrl: './page-block-form-question-select.component.html',
    styleUrls: ['./page-block-form-question-select.component.scss']
})
export class PageBlockFormQuestionSelectComponent implements OnInit {

    @Input() formGroup!: FormGroup;
    @Input() question!: Question;
    @Input() entrant?: Entrant;
    @Input() controller?: PageBlockFormQuestionComponent;

    controlName: string = this.question?.key;
    control: FormControl = this.formBuilder?.control('', []);
    selectedValue?: QuestionValue;

    constructor(private formBuilder: FormBuilder, public Validation: ValidationService) {
    }

    ngOnInit(): void {
        this.controlName = this.question.key;

        this.setValidation();
        this.Validation.setCustomValidation(this.question, this.question.options.validations, this.control);
    }

    setValidation(): void {
        this.formGroup.addControl(this.controlName, this.control);
        this.control.valueChanges.subscribe(selectedValue => {
            const selected = this.question.options.values?.find(option => option.value === selectedValue);
            this.selectedValue = selected;
        });

        setTimeout(() => {
            if (this.entrant && this.entrant?.answers && this.entrant?.answers[this.question.key]) {
                this.control.setValue(this.entrant?.answers[this.question.key]);
            } else {
                if (this.question.options.values?.find(option => option.default === true)) {
                    const defaultOption = this.question.options.values.find(option => option.default === true);
                    if (defaultOption?.value) {
                        this.control.setValue(defaultOption?.value);
                    }
                }
            }
        }, 250);
    }

    scopedDisplayFn(): any {
        return (selectedValue: string) => {
            if (this.question && this.question.options && this.question.options.values) {
                const selected = this.question.options.values?.find(option => option.value === selectedValue);
                this.selectedValue = selected;


                return selected?.label || '';
            } else {
                this.selectedValue = undefined;
                return '';
            }
        }
    }

    ngOnDestroy(): void {
        this.formGroup.removeControl(this.controlName);
    }

}
