
declare interface String {
    toUpperCaseFirst(): string;
    toLowerCaseFirst(): string;
    sanitise(type: string): string;
}

String.prototype.toUpperCaseFirst = function(): string {
    let s = this;
    const firstChar = s.charAt(0).toUpperCase();
    const restChars = s.slice(1, s.length);
    return firstChar + restChars;
};

String.prototype.toLowerCaseFirst = function(): string {
    let s = this;
    const firstChar = s.charAt(0).toLowerCase();
    const restChars = s.slice(1, s.length);
    return firstChar + restChars;
};

String.prototype.sanitise = function(type: string): string {
    const value = this;
    if (value && typeof value === 'string' && value.length > 0) {
        const sanitiseMap: any = {
            email: (input: string) => {
                let output = input.toString();
                output = output.replace(/\s/gi, '');
                return output;
            },
            name: (input: string) => {
                let output = input.toString();
                output = output.trim();
                output = output.replace(/[^a-zA-Z ]/gi, '');
                return output;
            },
        };
        if (typeof sanitiseMap[type] === 'function') {
            return sanitiseMap[type](value);
        } else {
            return value;
        }
    } else {
        return '';
    }
};
