<section [formGroup]="formGroup">
    <mat-form-field [appearance]="Validation.getAppearance(question)" [hideRequiredMarker]="Validation.getHideRequired(question)" [floatLabel]="Validation.getFloatLabel(question)">
        <mat-label>{{question.label}}</mat-label>
        <input id="q-{{question.key}}-value" type="text" matInput [placeholder]="Validation.getPlaceholder(question)" [formControlName]="controlName" [maxlength]="Validation.getCustomValidationAttribute(question,'maxLength')">

        <mat-hint *ngIf="question.options.branding?.hint">{{question.options.branding?.hint}}</mat-hint>

        <mat-error *ngFor="let error of Validation.getCustomValidationErrors(question, control)">
            {{error}}
        </mat-error>

        <mat-error *ngIf="control && control.hasError('required')">
            <span [innerHTML]="Validation.getRequiredMessage(question)">&nbsp;</span>
        </mat-error>
    </mat-form-field>
</section>