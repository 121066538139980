import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BrandingService } from 'src/app/services/branding.service';
import { UtilsPublicService } from 'src/app/services/utils-public.service';
import { PrizeGame } from 'src/interfaces/prizeGame';
import { PrizeGameCustom } from 'src/interfaces/prizeGameCustom';
import { PrizeTier } from 'src/interfaces/prizeTier';
import { WindowApp } from 'src/interfaces/windowApp';

declare var window: WindowApp;

@Component({
    selector: 'app-prize-game-custom',
    templateUrl: './prize-game-custom.component.html',
    styleUrls: ['./prize-game-custom.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrizeGameCustomComponent implements OnInit {
    // @Input() gameId?: string;
    @Input() hasWon!: boolean;
    @Input() game!: PrizeGame | PrizeGameCustom;
    @Input() siteId?: string;

    loading: boolean = true;

    constructor(private Branding: BrandingService, private UtilsPublic: UtilsPublicService, public Sanitiser: DomSanitizer, private changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        window.hasWon = this.hasWon;
        this.setup();

    }
    async setup(): Promise<void> {

        if (this.game && '_id' in this.game && this.game._id) {
            this.Branding.setCss(['/game-files/' + this.siteId + '/' + this.game._id + '.css?t=' + new Date().valueOf().toString()]);

            const selectedPrizeTier: PrizeTier = JSON.parse(localStorage.getItem('editorSelectedPrizeTier') as string);
            const selectedPrizeSetId: string = JSON.parse(localStorage.getItem('editorSelectedPrizeSetId') as string);

            let prizeTierId = '';
            let prizeId = '';
            let prizeDesc = '';
            let prizeLabel = '';
            let prizeSetId = '';
            if (selectedPrizeTier) {
                prizeDesc = selectedPrizeTier.description;
                prizeLabel = selectedPrizeTier.label;
                prizeTierId = selectedPrizeTier.tierId.toString();
            }
            if (selectedPrizeSetId) {
                prizeSetId = selectedPrizeSetId;
            }

            window.sdk.game.__setResults({
                hasWon: this.hasWon,
                prizeDesc,
                prizeId,
                prizeLabel,
                prizeSetId,
                prizeTierId,
                image: this.hasWon ? (this.game.options.win.image || '') : (this.game.options.lose.image || ''),
                message: this.hasWon ? (this.game.options.win.message || '') : (this.game.options.lose.message || ''),
            });

            console.clear();

            setTimeout(() => {
                if (this.game && '_id' in this.game && this.game._id) {
                    this.loading = false;
                    // ensure JS loads after the HTML is present
                    this.Branding.setJs(['/game-files/' + this.siteId + '/' + this.game._id + '.js?t=' + new Date().valueOf().toString()]);

                    this.changeDetectorRef.detectChanges();
                }
            }, 250);
        }
    }
}
