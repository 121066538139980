<section [formGroup]="formGroup">
    <p *ngIf="question.label && question.label.length">{{question.label}}</p>

    <input id="file-{{question.key}}" class="hidden" type="file" (change)="fileChange()" accept="{{allowedExtensionsAsString}}" />

    <mat-form-field class="hide-input">
        <input id="q-{{question.key}}-value" type="text" matInput [placeholder]="Validation.getPlaceholder(question)" [formControlName]="controlName">

        <mat-error *ngFor="let error of Validation.getCustomValidationErrors(question, control)">
            {{error}}
        </mat-error>

        <mat-error *ngIf="control && control.hasError('required')">
            <span [innerHTML]="Validation.getRequiredMessage(question)">&nbsp;</span>
        </mat-error>

        <mat-error *ngIf="customError">
            <strong>{{customError}}</strong>
        </mat-error>

        <mat-error *ngIf="control && control.hasError('requiredTrue') && !customError">
            <strong>There is an issue with the filename or extension</strong>
        </mat-error>

    </mat-form-field>

    <br *ngIf="customError" />
    <br *ngIf="customError" />


    <ul class="uploading-files">
        <li *ngIf="!selectedFiles.length" (click)="start();" class="clickable placeholder">
            <div class="preview">
                <mat-icon>add</mat-icon>
                <span>{{question.options.typeSpecificOptions.uploadButtonText || 'Upload files'}}</span>
            </div>
        </li>
        <li *ngFor="let file of selectedFiles; let idx = index;">
            <div class="controls">
                <button mat-icon-button color="warn" (click)="selectedFiles.splice(idx, 1); checkAmountRequirements();"><mat-icon>delete_forever</mat-icon></button>
            </div>
            <div class="preview" *ngIf="file.mime">
                <img *ngIf="file.mime.indexOf('image') !== -1" [src]="file.upload">
                <mat-icon *ngIf="file.mime.indexOf('image') === -1">document</mat-icon>
                <p>
                    <span class="filename">{{file.filename}}</span>
                    <span class="size"> - {{file.size / 1024 | number }}KB</span>
                </p>
            </div>
        </li>
        <li *ngIf="selectedFiles.length && question.options.typeSpecificOptions.multiple && selectedFiles.length <= (question.options.typeSpecificOptions.maxAmountOfFiles || 4) - 1" (click)="start();" class="clickable placeholder">
            <div class="preview">
                <mat-icon>add</mat-icon>
                <span>{{question.options.typeSpecificOptions.addMoreButtonText || 'Add more files'}}</span>
            </div>
        </li>
    </ul>


    <!-- <button *ngIf="!selectedFiles.length" color="primary" mat-raised-button (click)="start()">Upload file{{question.options.typeSpecificOptions.multiple ? 's' : ''}}</button> -->
    <!-- <button *ngIf="selectedFiles.length" color="warn" mat-raised-button (click)="selectedFiles = []; start()">Cancel and upload {{question.options.typeSpecificOptions.multiple ? ' different files' : ' a different file'}}</button> -->

</section>