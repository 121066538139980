<section [formGroup]="formGroup" class="{{control.dirty && control.invalid || question.options.dirty ? 'invalid': 'valid'}}">
    <mat-label class="{{question.label.length ? 'label': 'empty-label'}}">{{question.label}}</mat-label>
    <mat-radio-group aria-label="Select an option" [formControlName]="controlName">
        <mat-radio-button *ngFor="let value of question.options.values" [value]="value.value" [disabled]="value.disabled || !controller?.isAnswerVisible(question, value) ">
            <span [innerHTML]="value.label"></span>
        </mat-radio-button>
    </mat-radio-group>

    <mat-error *ngIf="control && control.hasError('required') && control.dirty">
        <span [innerHTML]="Validation.getRequiredMessage(question)">&nbsp;</span>
    </mat-error>
</section>
<br />