import { FormGroup } from '@angular/forms';
import { Question } from 'src/interfaces/question';
import { Form } from 'src/interfaces/form';
import { WindowApp } from 'src/interfaces/windowApp';
import { Injectable } from '@angular/core';
import { Entrant } from 'src/interfaces/entrant';

declare var window: WindowApp;
@Injectable({
    providedIn: 'root'
})
export class hiddenIfService {


    public isAcceptable(question: Question | any, form: Form, formGroupOrEntrant: FormGroup | Entrant | FormGroup[]): { result: boolean, message: string } {
        let acceptable: { result: boolean, message: string } = { result: true, message: '' };

        if (question.hiddenIf === true) {
            return { result: false, message: '' };

        } else if (question.hiddenIf === undefined || question.hiddenIf === false) {
            return { result: true, message: '' };
        } else if (question.hiddenIf.length > 0) {

            for (const hiddenObj of question.hiddenIf) { // it is an array
                if (acceptable.result === false) {
                    return acceptable;
                }

                let { key, value, valueNot, valueIn, valueNotIn, valueOr, patternMatch, patternNotMatch } = hiddenObj;

                if (key.indexOf('metadata') === 0) {
                    const entrant: Entrant = ('metadata' in formGroupOrEntrant ? formGroupOrEntrant : window.app.entrant) as Entrant;
                    if (entrant.metadata) {
                        const metaKey = key.replace('metadata.', '');
                        const metaValue = eval('entrant.metadata.' + metaKey);

                        if (value !== undefined && metaValue === value) {
                            acceptable = { result: false, message: hiddenObj.message || '' };
                            continue;
                        }
                        if (valueNot !== undefined && metaValue !== valueNot) {
                            acceptable = { result: false, message: hiddenObj.message || '' };
                            continue;
                        }
                    }
                }


                const answerValue = this.getAnswer(key, formGroupOrEntrant);
                const answerQuestion = form.questions[key];

                if (key === '[nowDate]') { // special hiddenIf for rendering based on current dateTime
                    const gtOrLt = Object.keys(hiddenObj)[1];
                    const dateValue = new Date(hiddenObj[gtOrLt]);

                    if ((gtOrLt === 'lt' && dateValue > new Date()) || (gtOrLt === 'gt' && dateValue < new Date())) { // hidden if current date is less than selected date
                        acceptable = { result: false, message: hiddenObj.message || '' };
                        continue;
                    }
                    acceptable = { result: true, message: '' };
                    continue;
                }

                if (key.indexOf('jQuery:') !== -1) {
                    const selector = hiddenObj.key.replace('jQuery:', '');
                    const assert = (hiddenObj.visible && hiddenObj.visible === 'visible');

                    if (window['$'](selector).is(':visible') !== assert) {
                        acceptable = { result: true, message: '' };
                    } else {
                        acceptable = { result: false, message: '' };
                    }

                    continue;
                }

                if (answerQuestion && answerQuestion.type && answerQuestion.type === 'date') {
                    // this is flattening timezones from the hiddenIF date logic
                    if (valueNot)
                        try { valueNot = valueNot ? new Date(new Date(valueNot).setHours(12)).toISOString() : valueNot; } catch (err) { console.error(err); }
                    if (value)
                        try { value = value ? new Date(new Date(value).setHours(12)).toISOString() : value; } catch (err) { console.error(err); }
                    if (valueIn)
                        try { valueIn.map((val: any) => { val = val ? new Date(new Date(val).setHours(12)).toISOString() : val; }) } catch (err) { console.error(err); }
                    if (valueNotIn)
                        try { valueNotIn.map((val: any) => { val = val ? new Date(new Date(val).setHours(12)).toISOString() : val; }) } catch (err) { console.error(err); }
                }

                if (answerValue && Array.isArray(answerValue)) { // is an array
                    const allValues = [];
                    for (const selectedValues of answerValue) {
                        if (typeof selectedValues === 'object' && selectedValues['value']) {
                            allValues.push(selectedValues['value']);
                        } else {
                            allValues.push(selectedValues);
                        }
                    }
                    if (value) {
                        acceptable = { result: !(allValues.indexOf(value) === -1), message: hiddenObj.message || '' };
                        continue;
                    }
                    if (valueNot) {
                        acceptable = { result: (allValues.indexOf(valueNot) !== -1), message: hiddenObj.message || '' };
                        continue;
                    }
                    if (valueIn) {
                        acceptable = { result: (allValues.indexOf(valueIn) === -1), message: hiddenObj.message || '' };
                        continue;
                    }
                    if (valueNotIn) {
                        acceptable = { result: (allValues.indexOf(valueNotIn) !== -1), message: hiddenObj.message || '' };
                        continue;
                    }
                    if (valueOr) {
                        acceptable = { result: !!(allValues.find(value => valueOr.includes(value))), message: hiddenObj.message || '' };
                        continue;
                    }

                    acceptable = { result: false, message: hiddenObj.message || '' };
                    continue;
                } else {
                    if (patternMatch && answerValue.match(new RegExp(patternMatch, 'i')) === null) {
                        acceptable = { result: false, message: hiddenObj.message || '' };
                        continue;
                    }
                    if (patternNotMatch && answerValue.match(new RegExp(patternNotMatch, 'i'))) {
                        acceptable = { result: false, message: hiddenObj.message || '' };
                        continue;
                    }
                    if (value !== undefined && answerValue === value) {
                        acceptable = { result: false, message: hiddenObj.message || '' };
                        continue;
                    }
                    if (valueNot !== undefined && answerValue !== valueNot) {
                        acceptable = { result: false, message: hiddenObj.message || '' };
                        continue;
                    }
                    if (valueIn !== undefined && answerValue.split(',').includes(valueIn)) {
                        acceptable = { result: false, message: hiddenObj.message || '' };
                        continue;
                    }
                    if (valueNotIn !== undefined && !answerValue.split(',').includes(valueNotIn)) {
                        acceptable = { result: false, message: hiddenObj.message || '' };
                        continue;
                    }
                    if (valueOr) {
                        acceptable = { result: valueOr.includes(answerValue), message: hiddenObj.message || '' };
                        continue;
                    }
                }
            }
        }
        return acceptable;
    }

    private getAnswer(key: string, formGroupOrEntrant: FormGroup | Entrant | FormGroup[]): string {
        if (formGroupOrEntrant && 'get' in formGroupOrEntrant && formGroupOrEntrant.get && formGroupOrEntrant.get(key)) {
            return formGroupOrEntrant.get(key)?.value;
        } else if (formGroupOrEntrant && 'answers' in formGroupOrEntrant && formGroupOrEntrant.answers && key in formGroupOrEntrant.answers) {
            return formGroupOrEntrant.answers[key];
        } else if (Array.isArray(formGroupOrEntrant)) {
            return formGroupOrEntrant.find(formGroup => formGroup.get(key))?.get(key)?.value || '';
        }
        return '';
    }
}