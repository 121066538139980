<div *ngIf="question.type && isQuestionVisible() && !loading" [ngSwitch]="question.type">
    <!-- Question Types -->

    <app-page-block-form-question-content *ngSwitchCase="'content'" [question]="question"></app-page-block-form-question-content>

    <app-page-block-form-question-email *ngSwitchCase="'email'" [question]="question" [entrant]="entrant" [formGroup]="formGroup"></app-page-block-form-question-email>
    <app-page-block-form-question-text *ngSwitchCase="'text'" [question]="question" [entrant]="entrant" [formGroup]="formGroup"></app-page-block-form-question-text>
    <app-page-block-form-question-telephone *ngSwitchCase="'telephone'" [question]="question" [entrant]="entrant" [formGroup]="formGroup"></app-page-block-form-question-telephone>
    <app-page-block-form-question-textarea *ngSwitchCase="'textarea'" [question]="question" [entrant]="entrant" [formGroup]="formGroup"></app-page-block-form-question-textarea>
    <app-page-block-form-question-radio *ngSwitchCase="'radio'" [question]="question" [entrant]="entrant" [formGroup]="formGroup" [controller]="controller"></app-page-block-form-question-radio>
    <app-page-block-form-question-checkbox *ngSwitchCase="'checkbox'" [question]="question" [entrant]="entrant" [formGroup]="formGroup" [controller]="controller"></app-page-block-form-question-checkbox>
    <app-page-block-form-question-select *ngSwitchCase="'select'" [question]="question" [entrant]="entrant" [formGroup]="formGroup" [controller]="controller"></app-page-block-form-question-select>
    <app-page-block-form-question-recaptcha *ngSwitchCase="'recaptcha'" [question]="question" [entrant]="entrant" [formGroup]="formGroup"></app-page-block-form-question-recaptcha>
    <app-page-block-form-question-code *ngSwitchCase="'code'" [question]="question" [entrant]="entrant" [formGroup]="formGroup"></app-page-block-form-question-code>
    <app-page-block-form-question-button *ngSwitchCase="'button'" [question]="question" [entrant]="entrant" [formGroup]="formGroup"></app-page-block-form-question-button>
    <app-page-block-form-question-autocomplete *ngSwitchCase="'autocomplete'" [question]="question" [entrant]="entrant" [formGroup]="formGroup" [controller]="controller"></app-page-block-form-question-autocomplete>
    <app-page-block-form-question-file *ngSwitchCase="'file'" [question]="question" [entrant]="entrant" [formGroup]="formGroup"></app-page-block-form-question-file>
    <app-page-block-form-question-address *ngSwitchCase="'address'" [question]="question" [entrant]="entrant" [formGroup]="formGroup"></app-page-block-form-question-address>
    <app-page-block-form-question-date *ngSwitchCase="'date'" [question]="question" [entrant]="entrant" [formGroup]="formGroup"></app-page-block-form-question-date>
    <app-page-block-form-question-dob *ngSwitchCase="'dob'" [question]="question" [entrant]="entrant" [formGroup]="formGroup"></app-page-block-form-question-dob>
    <app-page-block-form-question-bot *ngSwitchCase="'bot'" [question]="question" [entrant]="entrant" [formGroup]="formGroup"></app-page-block-form-question-bot>
    <app-page-block-form-question-mask *ngSwitchCase="'mask'" [question]="question" [entrant]="entrant" [formGroup]="formGroup"></app-page-block-form-question-mask>
    <app-page-block-form-question-slider *ngSwitchCase="'slider'" [question]="question" [entrant]="entrant" [formGroup]="formGroup"></app-page-block-form-question-slider>

    <!-- Default -->
    <div *ngSwitchDefault>
        <h1>Missing question type {{question.type}}</h1>
    </div>
</div>