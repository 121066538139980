<div *ngIf="data">
    <div *ngIf="data.showCloseButton" class="close-button-container" (click)="close()">
        <mat-icon>close</mat-icon>
    </div>
    <h1 mat-dialog-title>{{data.title}}</h1>
    <div class="content" mat-dialog-content [innerHTML]="data.content">
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="close()" cdkFocusInitial>{{data.dismissText || 'Ok'}}</button>
    </div>
</div>