import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { Entrant } from 'src/interfaces/entrant';
import { Question } from 'src/interfaces/question';
import { PageBlockFormQuestionComponent } from '../page-block-form-question.component';
import { UtilsPublicService } from 'src/app/services/utils-public.service';

@Component({
    selector: 'app-page-block-form-question-checkbox',
    templateUrl: './page-block-form-question-checkbox.component.html',
    styleUrls: ['./page-block-form-question-checkbox.component.scss']
})
export class PageBlockFormQuestionCheckboxComponent implements OnInit {

    @Input() formGroup!: FormGroup;
    @Input() question!: Question;
    @Input() entrant?: Entrant;
    @Input() controller?: PageBlockFormQuestionComponent;

    controlName: string = this.question?.key;
    control: FormControl = this.formBuilder?.control('', []);

    selected: { [key: string]: boolean } = {};
    answer: object[] = [];

    constructor(private formBuilder: FormBuilder, public Validation: ValidationService, private UtilsPublic: UtilsPublicService) {
    }

    ngOnInit(): void {
        this.controlName = this.question.key;

        if (this.question.options.values) {
            for (const option of this.question.options.values) {
                if (option.default) {
                    this.selected[option['value']] = true;
                } else {
                    this.selected[option['value']] = false;
                }
            }
        }

        this.setValidation();
        this.Validation.setCustomValidation(this.question, this.question.options.validations, this.control);
        this.setBranding();
    }

    modelChange(): void {
        this.control.markAsDirty();
        const answer = [];
        let answerText = '';

        let separator = '';

        for (const selected in this.selected) {
            if (this.selected[selected] === true) {
                answerText += separator;
                answerText += selected.toString();
                separator = ',';

                answer.push({ value: selected });
            }
        }
        if (this.answer !== answer) {
            this.answer = answer;
        }
        this.control.setValue(answerText);
    }

    setValidation(): void {
        this.formGroup.addControl(this.controlName, this.control);


        setTimeout(() => {
            if (this.entrant && this.entrant?.answers) {
                const selected: string | undefined = this.entrant?.answers[this.question.key];
                if (selected) {
                    selected.split(',').map((key) => {
                        if (key in this.selected) {
                            this.selected[key] = true;
                        }
                    });
                }
                this.modelChange();
            }
        }, 250);
    }

    setBranding(): void {
        if (this.question.options.typeSpecificOptions && this.question.options.typeSpecificOptions.backgroundColour && this.question.options.typeSpecificOptions.tickColour) {

            const css = `
            .key-${this.question.key} .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
            .key-${this.question.key} .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
                background-color: ${this.question.options.typeSpecificOptions.backgroundColour};
            }
            .key-${this.question.key} .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
            .key-${this.question.key} .mat-checkbox-checked.mat-accent .mat-checkbox-background {
                background-color: ${this.question.options.typeSpecificOptions.backgroundColour};
            }
            .key-${this.question.key} .mat-checkbox-checkmark-path {
                stroke: ${this.question.options.typeSpecificOptions.tickColour} !important;
            }
            
            `;
            this.UtilsPublic.loadCSSInline(css, this.question.key + '-checkbox-colour');
        }
    }

    ngOnDestroy(): void {
        this.formGroup.removeControl(this.controlName);
        this.UtilsPublic.loadCSSInline('', this.question.key + '-checkbox-colour');
    }

}
