import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { InteractDialogComponent } from '../components/interact-dialog/interact-dialog.component';
import { InteractConfirmComponent } from '../components/interact-confirm/interact-confirm.component';
import { InteractConfirmButtonsComponent } from '../components/interact-confirm-buttons/interact-confirm-buttons.component';
import { InteractLoadingComponent } from '../components/interact-loading/interact-loading.component';
import { InteractPromptComponent } from '../components/interact-prompt/interact-prompt.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WindowApp } from 'src/interfaces/windowApp';
import { UtilsPublicService } from './utils-public.service';
import { ThemePalette } from '@angular/material/core';

declare var window: WindowApp;

@Injectable({
    providedIn: 'root'
})
export class InteractService {

    public currentProgress: Subject<Object> = new Subject();
    public currentError: Subject<Object> = new Subject();
    private loadingProgress?: number;
    private loadingTotal?: number;
    private loadingCallback?: Function;

    constructor(public dialog: MatDialog, public snackBar: MatSnackBar, public UtilsPublic: UtilsPublicService) {
        if (this.UtilsPublic.isBrowser() && window && window.app)
            window.app.interact = this;
    }
    dialogRef: any;

    displayDialog({ title = 'Sorry!', content = 'Sorry, an error occurred! Please try again later', dismissText = 'Ok', showCloseButton = false, disableClose }: { title?: string, content?: string, dismissText?: string, showCloseButton?: boolean, disableClose?: boolean }): InteractService {
        title = title || 'Sorry!';
        content = content || 'Sorry, an error occurred! Please try again later';

        if (this.UtilsPublic.isBrowser()) {
            this.dialogRef = this.dialog.open(InteractDialogComponent, {
                data: { title, content, dismissText, showCloseButton }, disableClose
            });
        }
        return this;
    }

    displayConfirm({ title = 'Are you sure?', content = 'Are you sure?', successCallback, cancelCallback, disableClose }: { title: string, content: string, successCallback?: Function, cancelCallback?: Function, disableClose?: boolean }): InteractService {
        this.dialogRef = this.dialog.open(InteractConfirmComponent, {
            data: { title, content, successCallback, cancelCallback, dialogRef: this.dialogRef }, disableClose
        });
        return this;
    }
    displayLoading({ progress, total, finishedCallback, loadingMessage }: { progress?: number, total: number, finishedCallback?: Function, loadingMessage?: string }): InteractService {
        this.loadingProgress = progress || 0;
        this.currentProgress = new Subject();
        this.setProgress(this.loadingProgress);

        this.loadingTotal = total;
        this.loadingCallback = finishedCallback;

        if (window.sdk && window.sdk.customLoading) {
            window.sdk.events.trigger('loading');
        } else {
            this.dialogRef = this.dialog.open(InteractLoadingComponent, {
                disableClose: true,
                data: { currentProgress: this.currentProgress, currentError: this.currentError, total, loadingMessage }
            });
        }
        return this;
    }
    displayPrompt({ title = 'Please enter...', content = '', questionContent, defaultVal, dropdownOptions, successCallback }: { title: string, content: string, questionContent: string, defaultVal?: string, dropdownOptions?: { label: string, value: string }[], successCallback: Function }): InteractService {

        this.dialogRef = this.dialog.open(InteractPromptComponent, {
            width: '450px',
            data: { title, questionContent, content, dropdownOptions, defaultVal, successCallback }
        });

        return this;
    }

    displayConfirmButtons({title, content, buttons, disableClose}: {title: string, content: string, buttons: {label: string, color: ThemePalette, callback: Function}[], disableClose?: boolean}): InteractService{
        this.dialogRef = this.dialog.open(InteractConfirmButtonsComponent, {
            data: { title, content, buttons }, disableClose
        });
        return this;
    }

    displaySiteLockedOverlay(): void {
        setTimeout(() => {

            if (document.querySelector('mat-sidenav-content > router-outlet + *')) {
                const overlay = document.createElement('div');
                overlay.classList.add('site-locked-overlay');
                overlay.innerText = 'Site is currently locked.';

                document.querySelector('mat-sidenav-content > router-outlet + *')?.append(overlay);
            }

        }, 250)
    }
    displaySiteLockedSnackbar(): void {
        this.displaySnackbar({ message: 'Site is currently locked - making changes will not be possible!', action: 'Ok', duration: 15000, classes: ['snack-bar-important'] });
    }

    closeSiteLLockedOverlay(): void {
        document.querySelector('.site-locked-overlay')?.remove();
    }

    closeDialog(): InteractService {
        this.dialog.closeAll();
        return this;
    }

    addProgress(progress: number): InteractService {
        if (!this.loadingProgress) {
            this.loadingProgress = 0;
        }
        this.loadingProgress += progress;
        this.setProgress(this.loadingProgress);

        return this;
    }
    setProgress(progress: number): InteractService {
        this.loadingProgress = progress;
        this.currentProgress.next(progress);
        if (this.loadingProgress === this.loadingTotal) {
            window.sdk.events.trigger('loading-finish');
            if (typeof this.loadingCallback === 'function') {
                this.loadingCallback();
            }
        }
        return this;
    }
    setError(error: string): InteractService {
        this.currentError.next(error);
        this.dialogRef.disableClose = false;

        return this;
    }

    displaySnackbar({ message, action, duration, classes }: { message: string, action?: string, duration: number, classes?: string[] }): InteractService {
        // classes: snack-bar-important
        this.snackBar.open(message || 'Example snackbar', action || 'Close', { duration: (duration || 3000), panelClass: ['snack-bar'].concat(classes || []) });
        return this;
    }
}
