<div *ngIf="!loading">
    <div class="{{hasWon ? 'won':'lost'}}" *ngIf="type" [ngSwitch]="type">
        <app-prize-game-slide-reveal *ngSwitchCase="'slideReveal'" [type]="type" [hasWon]="hasWon" [game]="game" [revealedTrigger]="revealedTriggerEvent" [isAdmin]="isAdmin" [demo]="demo"></app-prize-game-slide-reveal>
        <app-prize-game-tap-reveal *ngSwitchCase="'tapReveal'" [type]="type" [hasWon]="hasWon" [game]="game" [revealedTrigger]="revealedTriggerEvent" [isAdmin]="isAdmin" [demo]="demo"></app-prize-game-tap-reveal>
        <app-prize-game-slot-machine *ngSwitchCase="'slotMachine'" [type]="type" [hasWon]="hasWon" [game]="game" [revealedTrigger]="revealedTriggerEvent" [isAdmin]="isAdmin" [demo]="demo"></app-prize-game-slot-machine>
        <app-prize-game-cards-high-low *ngSwitchCase="'cardsHighLow'" [type]="type" [hasWon]="hasWon" [game]="game" [revealedTrigger]="revealedTriggerEvent" [isAdmin]="isAdmin" [demo]="demo" [preview]="preview"></app-prize-game-cards-high-low>
        <app-prize-game-scratch-reveal *ngSwitchCase="'scratchReveal'" [type]="type" [hasWon]="hasWon" [game]="game" [revealedTrigger]="revealedTriggerEvent" [isAdmin]="isAdmin" [demo]="demo" [preview]="preview"></app-prize-game-scratch-reveal>
        <!--  -->
        <app-prize-game-custom *ngSwitchCase="'custom'" [hasWon]="hasWon" [game]="game" [siteId]="siteId"></app-prize-game-custom>
        <!-- Default -->
        <div *ngSwitchDefault>
            <h1>Missing type {{type}}</h1>
        </div>
    </div>
</div>