import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Response } from 'src/interfaces/response';
import { Config } from 'src/interfaces/config';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    constructor(private http: HttpClient) {

    }
    // database stuff needs to stay the same
    async getConfig(key?: string, isUniverse: boolean = true): Promise<any> {
        try {
            let url = '/api/configs?a=b';
            if (key)
                url += '&key=' + key;


            const params: { [param: string]: string | number | boolean } = isUniverse ? { autoDB: false, autoWorkspace: true } : {};
            const configResponse: Response | any = await firstValueFrom(this.http.get(url, { withCredentials: true, params }));
            if (configResponse && configResponse.status === 'ok' && configResponse.data) {

                return configResponse.data;
            }
        } catch (err: any) {
            console.error(err);
            return { data: {} };
        }

    }

    async putConfig(config: Config, isUniverse: boolean = true): Promise<any> {
        try {
            const params: { [param: string]: string | number | boolean } = isUniverse ? { autoDB: false, autoWorkspace: true } : {};
            const configResponse: Response | any = await firstValueFrom(this.http.put('/api/configs', config, { withCredentials: true, params }));
            if (configResponse && configResponse.status === 'ok' && configResponse.data) {

                return configResponse.data;
            }
        } catch (err: any) {
            console.error(err);
            return { data: {} };
        }

    }
}
